import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { updateSelectedPackage } from '../../features/order/orderSlice';
import { getCookie, setCookie } from '../../helpers/cookie';
import {
  updateIsOpenAuthModal,
  updateNextUrl,
} from '../../features/general/generalSlice';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';

const TotalPriceSummary = ({
  selectedEvent,
  title,
  price,
  packages,
  eventId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const [selectedPackage, setSelectedPackage] = useState({});

  const handleOrderPayment = () => {
    if (user) {
      navigate(`/event/${eventId}/order`);
    } else {
      if (isMobile) {
        navigate('/auth');
      } else {
        dispatch(updateIsOpenAuthModal(true));
      }
      dispatch(updateNextUrl(`/event/${eventId}/order`));
    }

    let totalQuantity = 0;

    packages.forEach((p) => {
      totalQuantity += p.qty;
    });

    const filteredPackage = packages.filter((p) => p.qty > 0);

    setSelectedPackage(filteredPackage);

    const payloads = {
      filteredPackage,
      totalQuantity,
      totalPrice: price,
    };

    setCookie('expiredTime', JSON.stringify(Date.now()));
    setCookie('selectedPackage', JSON.stringify(payloads));
    setCookie('selectedEvent', JSON.stringify(selectedEvent));

    // dispatch(updateSelectedPackage({payloads));
  };

  return (
    <div className="w-full m-auto sm:m-0 sticky sm:static bottom-[10px] right-0 left-0 z-20 flex justify-between items-center border-[3px] border-solid border-[#1551A7] rounded-2xl bg-white overflow-hidden">
      <div className="px-4 py-2">
        <h6 className="font-bold text-[#1551A7]">{title}</h6>
        <h6 className="font-bold">IDR {price}</h6>
      </div>
      <div className="h-full">
        {/* <NavLink to={`/event/${slug}/order`}> */}
        <button
          className={`${price !== '0' ? 'bg-[#1551a7] hover:bg-[#134a99]' : 'bg-gray-400'} sm:h-full h-[64px] text-white px-3 font-bold transition-colors`}
          disabled={price === '0'}
          onClick={handleOrderPayment}
        >
          LANJUTKAN
        </button>
        {/* </NavLink> */}
      </div>
    </div>
  );
};

export default TotalPriceSummary;
