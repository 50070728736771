import Cookies from 'js-cookie';

export const setCookie = (key, value, exp = 30) => {
  Cookies.set(key, value, {
    expires: exp,
  });
};

export const getCookie = (key) => {
  return Cookies.get(key);
};

export const removeCookie = (key) => {
  Cookies.remove(key);
};
