import { NavLink, useLocation } from 'react-router-dom';
import brandLogo from '../../assets/images/ticketing-blue.png';
import brandLogoWhite from '../../assets/images/ticketing-white.png';

const NavbarBrand = ({ className }) => {
  const location = useLocation();

  return (
    <div className={`w-28 sm:w-32 ${className}`}>
      <NavLink to="/">
        <img
          className="w-full"
          src={location.pathname === '/' ? brandLogo : brandLogoWhite}
          alt="Brand Logo"
        />
      </NavLink>
    </div>
  );
};

export default NavbarBrand;
