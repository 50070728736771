import heroAnimation from '../../assets/images/hero-animation.gif';
import backdropVideo from '../../assets/videos/backdrop-etupay.mp4';

const Hero = () => {
  return (
    <div>
      <video className="hidden sm:block" autoPlay loop muted>
        <source src={backdropVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* <img
        className="w-full hidden sm:block"
        src={heroAnimation}
        alt="Hero Animation"
      /> */}
    </div>
  );
};
export default Hero;
