import { useEffect, useRef, useState } from 'react';
import { UilCalender, UilMapMarker } from '@iconscout/react-unicons';
import { Formik, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import Switch from 'react-switch';
import formOrderImg from '../../assets/images/form-order-img.png';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getCookie } from '../../helpers/cookie';
import { convertISOToDate } from '../../helpers/date';
import Countdown from 'react-countdown';
import Swal from 'sweetalert2';
import {
  updateIsOpenAuthModal,
  updateIsOpenHamburgerMenu,
} from '../../features/general/generalSlice';
import { Checkbox } from '@mui/material';
import { Slide, toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import fallbackNoImage from '../../assets/images/default-banner.png';

const OrderSchema = Yup.object().shape({
  fullName: Yup.string().required('Nama lengkap harus diisi'),
  numberPhone: Yup.string()
    .min(10, 'Nomor handphone minimal harus 10')
    .max(14, 'Nomor handphone maksimal harus 14')
    .required('Nomor handphone harus diisi'),
  email: Yup.string().required('Email harus diisi'),
});

const FormOrder = () => {
  let { eventId } = useParams();
  const [detailEvent, setDetailEvent] = useState({});
  const [checked, setChecked] = useState(false);
  const [checkedTermPrivacy, setCheckedTermPrivacy] = useState(false);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const dispatch = useDispatch();
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const selectedPackage =
    getCookie('selectedPackage') && JSON.parse(getCookie('selectedPackage'));
  const selectedEvent =
    getCookie('selectedEvent') && JSON.parse(getCookie('selectedEvent'));
  const order = useSelector((state) => state.orderReducer.selectedPackage);
  const navigate = useNavigate();
  const expiredTime =
    getCookie('expiredTime') && JSON.parse(getCookie('expiredTime'));

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#FFF]'));
  }, [dispatch]);

  useEffect(() => {
    window.document.title = 'Order Event - E-Tupay by TunaiPay';
    getDetailEvent();
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  const getDetailEvent = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/event/${eventId}`, {
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });
      const results = response.data.data;

      setDetailEvent(results);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTransaction = async (values) => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';
    const formattedPackages = selectedPackage.filteredPackage.map((p) => ({
      id: p.id,
      qty: p.qty,
    }));

    try {
      setIsTransactionLoading(true);
      const payloads = {
        product_id: selectedEvent.id,
        package_ids: formattedPackages,
        // qty: selectedPackage.totalQuantity,
        additional_data: {
          fullname: values.fullName,
          email: values.email,
          phone: values.numberPhone,
          // ktp: values.numberOfIdentity || '-',
          code_referral: values.referralCode || '-',
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/transaction/create`,
        payloads,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );

      setIsTransactionLoading(false);

      window.open(data.data.url, '_blank');

      Swal.fire({
        icon: 'success',
        text: 'Mohon cek email anda untuk melihat tiket, jika telah selesai melakukan pembayaran',
        padding: '2rem',
      }).then(() => {
        window.location.href = '/my-order';
      });
    } catch (err) {
      setIsTransactionLoading(false);

      toast.error(err.response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Slide,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: '',
      numberPhone: '',
      email: '',
      referralCode: '',
      numberOfIdentity: '',
    },
    onSubmit: (values) => {
      handleTransaction(values);
    },
    validationSchema: OrderSchema,
  });

  const handleChange = (checked) => {
    setChecked(checked);

    if (!user) {
      dispatch(updateIsOpenAuthModal(true));
    } else {
      const { user: userAuth } = user;

      formik.setValues({
        fullName: checked ? userAuth.name : '',
        numberPhone: checked ? userAuth.phone : '',
        email: checked ? userAuth.email : '',
      });
    }
  };

  // const intialValues = {
  //   fullName: '',
  //   numberPhone: '',
  //   email: '',
  //   referralCode: '',
  //   numberOfIdentity: '',
  // };

  // const handleSubmit = (values, actions) => {
  //   console.log(values, actions);
  // };

  const coundownTimerCompletion = ({ minutes, seconds, completed }) => {
    if (completed) {
      Swal.fire({
        icon: 'success',
        text: 'Waktu pembayaran anda telah habis, silahkan ulangi proses dari awal lagi',
        showConfirmButton: false,
        timer: 3000,
        padding: '2rem',
      }).then(() => {
        navigate('/');
      });

      return '';
    } else {
      return `${minutes}:${seconds}`;
    }
  };

  const handleCheckedTermPrivacy = (event) => {
    setCheckedTermPrivacy(event.target.checked);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
      <div className="container flex flex-col sm:flex-row m-auto sm:mt-8 mb-28 gap-x-6 font-arOneSans">
        <div className="order-2 sm:order-1 sm:relative sm:flex-initial sm:w-[65%] p-4 sm:p-0">
          {/* <div className="order-2 sm:order-1 sm:flex-initial sm:w-[65%] p-4 sm:p-0"> */}
          <div className="sm:absolute z-10 w-full flex justify-between items-center bg-[#1551A7] rounded-xl py-4 px-5">
            {/* <div className="w-full flex justify-between items-center bg-[#1551A7] rounded-xl py-4 px-5"> */}
            <h4 className="font-bold text-white text-base sm:text-lg">
              Data Pemesan
            </h4>
            <div className="flex items-center gap-x-4">
              <Switch
                onChange={handleChange}
                checked={checked}
                checkedIcon={false}
                uncheckedIcon={false}
                offHandleColor="#C3C3C3"
                onHandleColor="#FFF"
                offColor="#FFF"
                onColor="#66A958"
                width={46}
                height={23}
              />
              <h5 className="text-sm sm:text-md text-white text-md">
                Gunakan Data Saya
              </h5>
            </div>
          </div>
          {/* <div className="sm:absolute sm:top-11 sm:left-0 sm:right-0 sm:border-2 sm:rounded-md sm:border-solid sm:border-[#0B3568]/20"> */}
          <div className="sm:border-2 sm:rounded-md sm:border-solid sm:border-[#0B3568]/20 sm:mt-4">
            {/* <Formik onSubmit={formik.handleSubmit}> */}
            {/* {(props) => ( */}
            <form
              className="flex flex-col gap-y-8 px-1 sm:px-6 py-4 sm:pb-8 sm:pt-16"
              // className="flex flex-col gap-y-8 px-1 sm:px-6 py-4 sm:py-5"
              onSubmit={formik.handleSubmit}
            >
              {/* <div className="flex justify-between mb-2">
              <h5 className="font-bold text-lg">Detail Pemesan</h5>
              <div className="flex items-center gap-x-4">
                <Switch
                  onChange={handleChange}
                  checked={checked}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offHandleColor="#FFF"
                  onHandleColor="#FFF"
                  offColor="#C3C3C3"
                  onColor="#66A958"
                  width={46}
                  height={24}
                />
                <h5 className="text-sm sm:text-md text-black text-md">
                  Gunakan data saya
                </h5>
              </div>
            </div> */}
              <div className="flex flex-col gap-y-3">
                <div className="flex flex-wrap gap-y-3 sm:gap-x-6">
                  <div className="flex flex-col flex-1 gap-y-2">
                    <label className="font-medium" htmlFor="fullName">
                      Nama Lengkap
                    </label>
                    <input
                      className="outline-none px-4 py-3 border-dashed border-2 border-[#0B3568] rounded-lg"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fullName}
                      name="fullName"
                    />
                    {formik.errors.fullName && formik.touched.fullName ? (
                      <div className="text-xs text-[#D22E2E]">
                        {formik.errors.fullName}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col flex-1 gap-y-2">
                    <label className="font-medium" htmlFor="numberPhone">
                      Nomor Handphone
                    </label>
                    <input
                      className="outline-none px-4 py-3 border-dashed border-2 border-[#0B3568] rounded-lg"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.numberPhone}
                      name="numberPhone"
                    />
                    {formik.errors.numberPhone && formik.touched.numberPhone ? (
                      <div className="text-xs text-[#D22E2E]">
                        {formik.errors.numberPhone}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-wrap gap-y-3 sm:gap-x-6">
                  <div className="flex flex-col flex-1 gap-y-2">
                    <label className="font-medium" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="outline-none px-4 py-3 border-dashed border-2 border-[#0B3568] rounded-lg"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      name="email"
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <div className="text-xs text-[#D22E2E]">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex flex-col flex-1 gap-y-2">
                    <label className="font-medium" htmlFor="referralCode">
                      Kode Referal
                    </label>
                    <input
                      className="outline-none px-4 py-3 border-dashed border-2 border-[#0B3568] rounded-lg"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.referralCode}
                      name="referralCode"
                    />
                  </div>
                </div>
                <div className="mt-2 flex gap-x-2">
                  <Checkbox
                    checked={checkedTermPrivacy}
                    onChange={handleCheckedTermPrivacy}
                    sx={{ padding: 0 }}
                    id="termAndCondition"
                  />
                  <label
                    className="text-base font-semibold"
                    htmlFor="termAndCondition"
                  >
                    Dengan mengklik "Lanjut", kamu menyetujui{' '}
                    <NavLink
                      className="underline text-[#1551A7]"
                      to="/term-and-condition"
                    >
                      Syarat & Ketentuan{' '}
                    </NavLink>
                    dan{' '}
                    <NavLink
                      className="underline text-[#1551A7]"
                      to="/privacy-policy"
                    >
                      Kebijakan Privasi
                    </NavLink>{' '}
                    E-Tupay.
                  </label>
                  {/* <h6 className="text-sm">
                  <span className="text-[#df5959]">*</span>
                  Kami tidak pernah membagi data kamu kepada siapapun
                </h6> */}
                </div>
                <div className="flex flex-col sm:flex-row gap-y-6 sm:gap-x-6 sm:items-end mt-3">
                  {/* <div className="flex flex-col flex-1 gap-y-2">
                  <label className="font-medium" htmlFor="numberOfIdentity">
                    Nomor KTP/SIM/Kartu Pelajar
                  </label>
                  <input
                    className="outline-none px-4 py-3 border-dashed border-2 border-[#0B3568] rounded-lg"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.numberOfIdentity}
                    name="numberOfIdentity"
                  />
                </div> */}
                  <div className="flex-1 gap-y-2 self-end w-full">
                    <button
                      className={`${checkedTermPrivacy ? 'bg-[#1551a7] hover:bg-[#134a99]' : 'bg-gray-400 cursor-not-allowed'} py-3 w-full h-full text-white font-semibold rounded-lg`}
                      type="submit"
                      disabled={!checkedTermPrivacy}
                    >
                      {isTransactionLoading ? 'Sedang Proses...' : 'Lanjutkan'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {/* )}
          </Formik> */}
          </div>
        </div>
        <div className="order-1 sm:order-2 sm:relative sm:flex-initial sm:w-[35%]">
          {/* <div className="order-1 sm:order-2 sm:flex-initial sm:w-[35%]"> */}
          <div className="sm:absolute hidden w-full z-10 sm:flex items-center bg-[#1551A7] rounded-xl py-4 px-5">
            {/* <div className="hidden w-full sm:flex items-center bg-[#1551A7] rounded-xl py-4 px-5"> */}
            <h4 className="font-bold text-white text-lg">Detail Tiket</h4>
          </div>
          <div className="flex flex-col gap-y-4 sm:relative pt-2 sm:pt-8 p-5 sm:bottom-[15px] sm:top-11 sm:left-0 sm:right-0 sm:border-2 sm:rounded-md sm:border-solid sm:border-x-[#0B3568]/20 sm:border-b-[#0B3568]/20">
            {/* <div className="flex flex-col gap-y-4 pt-2 sm:pt-5 p-5 sm:bottom-[15px] sm:top-11 sm:left-0 sm:right-0 sm:border-2 sm:rounded-md sm:border-solid sm:border-x-[#0B3568]/20 sm:border-b-[#0B3568]/20 sm:mt-4"> */}
            {/* <div className="flex gap-x-4 bg-yellow-300 rounded-md py-3 px-4">
            <h6 className="border-r-[1px] border-r-gray-600 pr-4">
              <Countdown
                date={expiredTime + 1800000}
                renderer={coundownTimerCompletion}
              />
            </h6>
            <h6>Segera selesaikan pesananmu</h6>
          </div> */}
            <div>
              <img
                className="w-full rounded-lg"
                src={detailEvent.medias?.[0]?.path || fallbackNoImage}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = fallbackNoImage;
                }}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <h4 className="font-bold text-xl capitalize">
                {detailEvent.title}
              </h4>
              <div className="flex flex-col gap-y-2">
                <div className="flex gap-x-3 items-center">
                  <div>
                    <UilCalender size="20" color="#434343" />
                  </div>
                  <p className="font-semibold text-[#0C376BCC]/80">
                    {detailEvent.start_date &&
                      convertISOToDate(detailEvent.start_date)}
                  </p>
                </div>
                <div className="flex gap-x-3 items-center">
                  <div>
                    <UilMapMarker size="20" color="#434343" />
                  </div>
                  <p className="font-semibold text-[#0C376BCC]/80 capitalize">
                    {detailEvent.location}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3">
              {/* <div>
              <p>Number of Tickets</p>
              <h5 className="font-bold text-xl">
                {selectedPackage.totalQuantity} Tiket
              </h5>
            </div> */}
              <div>
                <p>Price</p>
                <h4 className="font-bold text-2xl">
                  IDR {selectedPackage.totalPrice} ({' '}
                  {selectedPackage.totalQuantity} Ticket )
                </h4>
              </div>
              <div>
                {selectedPackage.filteredPackage.map((p) => {
                  return (
                    <p className="capitalize">
                      <span className="font-medium">{`${p.name}`}</span>
                      {` - ${p.qty} Ticket`}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormOrder;
