import { useEffect, useRef, useState } from 'react';
import {
  UilCalender,
  UilMapMarker,
  UilImport,
  UilMoneyBill,
  UilTicket,
} from '@iconscout/react-unicons';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import myTicketImg from '../../assets/images/my-ticket-img.png';
import Overlay from '../../components/Overlay';
import OrderCard from '../../components/OrderCard';
import brandLogo from '../../assets/images/ticketing-blue.png';
import ticketExample from '../../assets/images/ticket-example.png';
import axios from 'axios';
import { getCookie } from '../../helpers/cookie';
import { convertISOToDate } from '../../helpers/date';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { convertNumberToCurrency } from '../../helpers/number';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import OrderDetailCard from '../../components/OrderDetailCard';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';
import fallbackNoImage from '../../assets/images/default-banner.png';
import './index.css';

const OrderDetail = () => {
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const ticketRef = useRef(null);
  const [orders, setOrders] = useState([]);
  const [detailTicketOnModal, setDetaiTicketOnModal] = useState({});
  const dispatch = useDispatch();
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const selectedOrder = useSelector(
    (state) => state.orderReducer.selectedOrder,
  );
  const theme = useTheme();
  const isMobileByMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    window.document.title = 'Order Detail - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#FFF]'));
  }, [dispatch]);

  const handleShowTicket = (detailTicket) => {
    console.log(detailTicket);
    setIsTicketOpen((prevState) => !prevState);
    setDetaiTicketOnModal(detailTicket);
  };

  const generateTickets = () => {
    const ticketsEl = [];
    selectedOrder.product.tickets.forEach((ticket) => {
      ticketsEl.push(
        <tr className="border-2">
          <td className="text-center p-6 font-bold">
            {selectedOrder.created_at}
          </td>
          <td>
            <div className="flex my-6 gap-x-5">
              <div className="basis-3/12 w-14">
                <img
                  className="w-full h-[120px] sm:h-20 object-cover rounded-md"
                  src={selectedOrder.product.medias[0].path || fallbackNoImage}
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = fallbackNoImage;
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-3 basis-9/12">
                <h3 className="font-bold capitalize">
                  {selectedOrder.product.title}
                </h3>
                <div className="flex flex-col gap-y-2">
                  <div className="flex gap-x-3 items-center">
                    <div>
                      <UilCalender size="18" color="#434343" />
                    </div>
                    <p className="text-sm font-semibold text-[#0C376BCC]/80">
                      {selectedOrder.product.start_date &&
                        convertISOToDate(selectedOrder.product.start_date)}
                    </p>
                  </div>
                  <div className="flex gap-x-3 items-center">
                    <div>
                      <UilMapMarker size="18" color="#434343" />
                    </div>
                    <p className="text-sm font-semibold text-[#0C376BCC]/80 capitalize">
                      {selectedOrder.product.city}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td className="text-center p-6 font-bold capitalize">
            {ticket.name}
          </td>
          <td className="text-center p-6 font-bold">
            Rp {convertNumberToCurrency(ticket.price, 'id-ID')}
          </td>
          <td className="text-center p-6 font-bold">
            <button
              className={`text-sm ${selectedOrder.order.status === 'PAID' ? 'bg-[#1551A7]' : 'bg-gray-400 cursor-not-allowed'} py-4 px-10 font-bold text-white rounded-xl`}
              onClick={() =>
                handleShowTicket({
                  category: ticket.name,
                  price: ticket.price,
                  ticketId: ticket.id,
                })
              }
              disabled={selectedOrder.order.status !== 'PAID'}
            >
              Lihat
            </button>
          </td>
        </tr>,
      );
    });

    return ticketsEl;
  };

  const filter = (node) => {
    const exclusionClasses = ['download-btn'];
    return !exclusionClasses.some((classname) =>
      node.classList?.contains(classname),
    );
  };

  const handleDownloadTicket = (eventTitle, ticketCategory) => {
    if (ticketRef.current) {
      // ticketRef.current.classList.remove('m-auto');
      // Calculate the size of the element
      const elementWidth = ticketRef.current.clientWidth;
      const elementHeight = ticketRef.current.clientHeight;
      const clipWidth = 200; // Width of the captured area
      const clipHeight = 200;
      // Set the style width and height to prevent cropping
      const options = {
        width: elementWidth,
        height: elementHeight,
        style: {
          width: `${elementWidth}px`,
          height: `${elementHeight}px`,
        },
        clip: {
          x: (elementWidth - clipWidth) / 2,
          y: (elementHeight - clipHeight) / 2,
          width: clipWidth,
          height: clipHeight,
        },
        filter: filter,
      };

      htmlToImage
        .toJpeg(ticketRef.current, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = `${eventTitle} - ${ticketCategory}.png`;
          link.click();
        })
        .catch((error) => {
          console.error('Failed to download image', error);
        });
    }
  };

  return (
    <>
      <div className="container m-auto mt-2 sm:mt-8 font-arOneSans">
        {isMobile ? (
          <div className="px-3">
            <h3 className="font-bold text-lg capitalize">Order Detail</h3>
            <div className="flex flex-col gap-y-3 mt-4">
              <div className="flex flex-col">
                <h6 className="font-bold ">{selectedOrder.created_at}</h6>
                <OrderDetailCard
                  title={selectedOrder.product.title}
                  poster={selectedOrder.product.medias[0].path}
                  startDate={selectedOrder.product.start_date}
                  city={selectedOrder.product.city}
                  // category={ticket.name}
                  // price={`Rp ${convertNumberToCurrency(ticket.price, 'id-ID')}`}
                >
                  {selectedOrder.product.tickets.length > 0 ? (
                    selectedOrder.product.tickets.map((ticket, idx) => (
                      <div
                        className={`flex justify-between items-center border-t-[2px] ${idx === selectedOrder.product.tickets.length - 1 ? 'border-b-[2px] pb-4' : 'pb-2'}  border-dashed pt-3 `}
                      >
                        <div className="font-semibold">
                          <div className="flex gap-x-2 items-center">
                            <div>
                              <UilTicket size="16" color="#434343" />
                            </div>
                            <p className="text-[#0C376B]/80 text-sm capitalize">
                              {ticket.name}
                            </p>
                          </div>
                          <div className="flex gap-x-2 items-center">
                            <div>
                              <UilMoneyBill size="16" color="#434343" />
                            </div>
                            <p className="text-[#0C376B]/80 text-sm">{`Rp ${convertNumberToCurrency(ticket.price, 'id-ID')}`}</p>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            handleShowTicket({
                              category: ticket.name,
                              price: ticket.price,
                              ticketId: ticket.id,
                            })
                          }
                          disabled={selectedOrder.order.status !== 'PAID'}
                          className={`text-xs ${selectedOrder.order.status === 'PAID' ? 'bg-[#1551A7]' : 'bg-gray-400 cursor-not-allowed'} h-fit py-2 px-3 font-bold text-white rounded-lg`}
                        >
                          Lihat
                        </button>
                      </div>
                    ))
                  ) : (
                    <h3>Belum ada data</h3>
                  )}
                </OrderDetailCard>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h3 className="capitalize font-bold text-base sm:text-lg mb-6 text-center">
              Order Detail
            </h3>
            <table className="w-full border-collapse border-spacing-0">
              <thead>
                <tr className="bg-[#1551A7] text-white">
                  <th className="text-center w-[20%] py-4 px-6 rounded-l-xl">
                    Tanggal Transaksi
                  </th>
                  <th className="text-center w-[30%] py-3 px-5">
                    Detail Event
                  </th>
                  <th className="text-center w-[10%] py-3 px-5">
                    Kategori Tiket
                  </th>
                  <th className="text-center w-[20%] py-3 px-5">Harga</th>
                  <th className="text-center w-[20%] py-3 px-5 rounded-r-xl">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>{generateTickets()}</tbody>
            </table>
          </>
        )}
      </div>

      <Overlay
        isOpen={isTicketOpen}
        onClose={() => setIsTicketOpen(!isTicketOpen)}
      >
        <div className="content-scrollbar bg-white fixed top-5 right-0 bottom-5 left-0 sm:m-auto z-50 sm:w-fit mx-4 overflow-y-auto rounded-md ">
          <div ref={ticketRef} className="bg-white py-6 px-10">
            <div className="flex flex-col gap-y-4">
              <div className="flex pb-2 justify-between border-b-2 border-dashed border-black/15">
                <div className="w-32">
                  <img className="w-full" src={brandLogo} alt="Brand Logo" />
                </div>
                <div className="download-btn flex items-center">
                  <button
                    onClick={() =>
                      handleDownloadTicket(
                        selectedOrder.product.title,
                        detailTicketOnModal.category,
                      )
                    }
                    className="font-semibold flex gap-x-2 items-center"
                  >
                    <UilImport size="18" color="#434343" />
                    <span>E-Ticket</span>
                  </button>
                </div>
              </div>
              <div className="flex gap-y-10 sm:gap-y-0 gap-x-6 flex-col sm:flex-row pb-6 border-b-2 border-dashed border-black/15s">
                <div className="flex flex-col gap-y-4">
                  <div className="flex flex-col gap-y-3 w-full pb-6 border-b-2 border-dashed border-black/15">
                    <h6 className="capitalize font-medium">
                      {selectedOrder.product.title}
                    </h6>
                    <img
                      className="w-80 rounded-lg"
                      src={
                        selectedOrder.product.medias[0].path || fallbackNoImage
                      }
                      alt={selectedOrder.product.title}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = fallbackNoImage;
                      }}
                    />
                  </div>

                  {!isMobileByMediaQuery ? (
                    <table className="border-separate border-spacing-y-4 w-[30rem]">
                      <tbody>
                        <tr>
                          <td>
                            <p className="text-sm">Nomor Transaksi</p>
                            <h6 className="font-semibold">
                              {selectedOrder.order.invoice}
                            </h6>
                          </td>
                          <td>
                            <p className="text-sm">Nama Customer</p>
                            <h6 className="font-semibold">
                              {selectedOrder.customer.name}
                            </h6>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <p className="text-sm">Kategori</p>
                            <h6 className="font-semibold capitalize">
                              {detailTicketOnModal.category}
                            </h6>
                          </td>
                          <td>
                            <p className="text-sm">Harga</p>
                            <h6 className="font-semibold">
                              Rp{' '}
                              {convertNumberToCurrency(
                                detailTicketOnModal.price,
                                'id-ID',
                              )}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <p className="text-sm">Tanggal Acara</p>
                            <h6 className="font-semibold">
                              {moment(selectedOrder.product.start_date).format(
                                'DD/MM/YYYY',
                              )}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <p className="text-sm">Lokasi Acara</p>
                            <h6 className="font-semibold">
                              {selectedOrder.product.location}
                            </h6>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <div className="flex flex-col gap-y-5">
                      <div className="flex flex-col sm:flex-row items-start gap-y-4 sm:gap-x-20 ">
                        <div className="basis-4/12">
                          <p className="text-sm">Nomor Transaksi</p>
                          <h6 className="font-semibold">
                            {selectedOrder.order.invoice}
                          </h6>
                        </div>
                        <div className="basis-8/12">
                          <p className="text-sm">Nama Customer</p>
                          <h6 className="font-semibold">
                            {selectedOrder.customer.name}
                          </h6>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row  sm:items-center gap-y-4 sm:gap-x-20">
                        <div className="basis-5/12">
                          <p className="text-sm">Kategori</p>
                          <h6 className="font-semibold capitalize">
                            {detailTicketOnModal.category}
                          </h6>
                        </div>
                        <div className="basis-4/12">
                          <p className="text-sm">Harga</p>
                          <h6 className="font-semibold">
                            Rp{' '}
                            {convertNumberToCurrency(
                              detailTicketOnModal.price,
                              'id-ID',
                            )}
                          </h6>
                        </div>
                        <div className="basis-3/12">
                          <p className="text-sm">Tanggal Acara</p>
                          <h6 className="font-semibold">
                            {moment(selectedOrder.product.start_date).format(
                              'DD/MM/YYYY',
                            )}
                          </h6>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-start gap-y-4 sm:gap-x-20">
                        <div className="">
                          <p className="text-sm">Lokasi Acara</p>
                          <h6 className="font-semibold">
                            {selectedOrder.product.location}
                          </h6>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="sm:pl-8 sm:border-l-2 sm:border-dashed sm:border-black/15 flex flex-col justify-center gap-y-4 text-center items-center">
                  <QRCode value={detailTicketOnModal.ticketId} size={200} />
                  <h6 className="text-orange-400 font-bold">
                    {detailTicketOnModal.ticketId}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default OrderDetail;
