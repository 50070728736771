import { useLocation } from 'react-router-dom';

const NavbarUserActions = ({ handleOpenAuthenticationModal }) => {
  const location = useLocation();

  return (
    <div className="flex gap-x-4">
      <button
        className={`py-2 px-6 bg-transparent border-2 border-[#1551A7] rounded-lg ${location.pathname === '/' ? 'text-[#1551A7] border-[#1551A7]' : 'text-white border-white'} font-bold text-sm`}
        onClick={handleOpenAuthenticationModal}
      >
        DAFTAR
      </button>
      <button
        className={`py-2 px-6 ${location.pathname === '/' ? 'bg-[#1551A7]' : 'border-2 border-white'} text-white rounded-lg text-sm font-bold`}
        onClick={handleOpenAuthenticationModal}
      >
        MASUK
      </button>
    </div>
  );
};

export default NavbarUserActions;
