import { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import {
  UilSearch,
  UilUser,
  UilParcel,
  UilSignOutAlt,
} from '@iconscout/react-unicons';
import { ConsoleView, isMobile } from 'react-device-detect';
import NavbarBrand from '../NavbarBrand';
import NavbarUserMenu from '../NavbarUserMenu';
import SearchInput from '../SearchInput';
import NavbarUserActions from '../NavbarUserActions';
import Overlay from '../Overlay';
import brandLogo from '../../assets/images/ticketing-blue.png';
import iconGoogle from '../../assets/icons/google.png';
import axios from 'axios';
import { getCookie, removeCookie, setCookie } from '../../helpers/cookie';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateIsOpenAuthModal,
  updateIsOpenHamburgerMenu,
  updateNextUrl,
} from '../../features/general/generalSlice';
import Swal from 'sweetalert2';
import OTPInput from 'react-otp-input';
import Countdown from 'react-countdown';
import * as Yup from 'yup';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { updateTotalOrders } from '../../features/order/orderSlice';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email masih belum benar')
    .matches(/@[^.]*\./, { message: 'Format email masih belum benar' })
    .required('Email harus diisi'),
});

const SignInSchema = Yup.object().shape({
  phone: Yup.string().required('Email atau nomor telepon harus diisi'),
  password: Yup.string().required('Password harus diisi'),
});

const SignUpSchema = Yup.object().shape({
  fullName: Yup.string().required('Nama lengkap harus diisi'),
  numberPhone: Yup.string()
    .min(10, 'Nomor handphone minimal harus 10')
    .max(14, 'Nomor handphone maksimal harus 14')
    .required('Nomor handphone harus diisi'),
  email: Yup.string()
    .email('Format email masih belum benar')
    .matches(/@[^.]*\./, { message: 'Format email masih belum benar' })
    .required('Email harus diisi'),
  password: Yup.string()
    .min(8, 'Password minimal harus 8 karakter')
    .matches(/^[A-Z]/, 'Password harus diawali dengan huruf kapital')
    .required('Password harus diisi'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Konfirmasi password harus sesuai',
  ),
});

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpenAuthenticationModal, setIsOpenAuthenticationModal] =
    useState(false);
  const [isDisableResendOTP, setIsDisableResendOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isVerification, setIsVerification] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [otp, setOtp] = useState('');
  const [emailTemp, setEmailTemp] = useState('');
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const totalOrders = useSelector((state) => state.orderReducer.totalOrders);
  const isOpenAuthModal = useSelector(
    (state) => state.generalReducer.isOpenAuthModal,
  );
  const isOpenHamburgerMenu = useSelector(
    (state) => state.generalReducer.isOpenHamburgerMenu,
  );
  // const [totalOrders, setTotalOrders] = useState(0);
  const nextUrl = useSelector((state) => state.generalReducer.nextUrl);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const getOrders = useCallback(async () => {
    const user = getCookie('user') && JSON.parse(getCookie('user'));

    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/transaction/tickets`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const results = response.data.data;

      dispatch(updateTotalOrders(results.transactions.length));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (responseToken) => {
      const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';
      const responseUserInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${responseToken.access_token}`,
          },
        },
      );

      const { data } = responseUserInfo;

      try {
        const responseSignInWithGoogle = await axios.post(
          `${BASE_URL}/auth/check-email`,
          {
            email: data.email,
          },
        );

        setCookie('user', JSON.stringify(responseSignInWithGoogle.data.data));
        dispatch(updateIsOpenAuthModal(false));
        navigate(nextUrl ? nextUrl : '/');
        dispatch(updateNextUrl(''));
        getOrders();
      } catch (err) {
        setIsLogin(false);
        formikSignUp.setValues({ email: data.email, fullName: data.name });
      }
    },
    onError: () => {},
  });

  const handleSubmit = async (values, actions) => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const url = isLogin ? '/auth/login' : '/auth/register';

      const payloads = isLogin
        ? {
            phone: values.phone.trim(),
            password: values.password,
          }
        : {
            email: values.email,
            fullname: values.fullName,
            phone: values.numberPhone,
            password: values.password,
          };

      const response = await axios.post(`${BASE_URL}${url}`, payloads);

      if (!isLogin) {
        setEmailTemp(values.email);
        setIsVerification(true);
      } else {
        const { data } = response.data;
        if (!data.user.email_activated) {
          setIsVerification(true);
          setEmailTemp(values.phone);
        } else {
          setCookie('user', JSON.stringify(response.data.data));
          dispatch(updateIsOpenAuthModal(false));
          navigate(nextUrl ? nextUrl : '/');
          dispatch(updateNextUrl(''));
          getOrders();
        }
      }
    } catch (err) {
      const { data } = err.response;

      Swal.fire({
        title: 'Login Gagal!',
        text: data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };

  const formikSignUp = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      numberPhone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleCloseAuthModal = () => {
    formikSignUp.setValues({
      fullName: '',
      email: '',
      numberPhone: '',
      password: '',
      confirmPassword: '',
    });
    setIsVerification(false);
    setIsLogin(true);
    dispatch(updateIsOpenAuthModal(!isOpenAuthModal));
  };

  const handleResendOTP = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      setIsDisableResendOTP(true);
      const payloads = { email: emailTemp };
      await axios.post(`${BASE_URL}/auth/resend-otp`, payloads);

      Swal.fire({
        title: 'Permintaan Terkirim!',
        text: 'Segera cek email untuk mendapatkan OTP',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (err) {
      setIsLoading(false);

      Swal.fire({
        title: 'Permintaan Gagal!',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  const coundownTimerCompletion = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsDisableResendOTP(false);
      return '';
    } else {
      return (
        <span className="text-sm font-medium">
          ({minutes}:{seconds})
        </span>
      );
    }
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();

    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const payloads = { email: emailTemp, otp };
      const response = await axios.post(
        `${BASE_URL}/auth/verify-otp`,
        payloads,
      );

      formikSignUp.setValues({
        fullName: '',
        email: '',
        numberPhone: '',
        password: '',
        confirmPassword: '',
      });
      formikSignUp.handleReset();
      setOtp('');
      setIsVerification(false);
      setCookie('user', JSON.stringify(response.data.data));
      dispatch(updateIsOpenAuthModal(false));
      getOrders();

      Swal.fire({
        title: 'Verifikasi Anda Berhasil!',
        icon: 'success',
        confirmButtonText: 'Ok',
      });

      navigate(nextUrl ? nextUrl : '/');
      dispatch(updateNextUrl(''));
    } catch (err) {
      Swal.fire({
        title: 'Permintaan Gagal!',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  const handleSendOTPByEmail = async (values) => {
    const { email } = values;
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      setIsLoadingForgotPassword(true);
      const payloads = { email };
      const response = await axios.post(
        `${BASE_URL}/auth/forgot-password`,
        payloads,
      );

      setIsLoadingForgotPassword(false);
      dispatch(updateIsOpenAuthModal(false));
      setIsForgotPassword(false);

      Swal.fire({
        title: 'Permintaan anda berhasil',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: false,
        timer: 4000,
      });
    } catch (err) {
      setIsLoadingForgotPassword(false);
      Swal.fire({
        title: 'Permintaan Gagal!',
        text: err.data.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  const handleLogoutMobile = () => {
    navigate('/');
    removeCookie('user');
  };

  // const getOrders = async () => {
  //   const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

  //   try {
  //     const response = await axios.get(`${BASE_URL}/transaction/tickets`, {
  //       headers: {
  //         Authorization: `Bearer ${user.token}`,
  //       },
  //     });
  //     const results = response.data.data;

  //     setTotalOrders(results.transactions.length);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   getOrders();
  //   return () => {};
  // }, [totalOrders]);

  return (
    <>
      <nav
        className={`container sm:flex sm:justify-between sm:items-center rounded-lg z-20 w-[95%] border-[1px] border-solid border-white py-1 pl-1 pr-3 sm:border-none sm:w-[100%] sm:py-[18px] sm:px-[38px] sm:rounded-2xl sm:gap-x-24 ${location.pathname === '/' ? 'bg-white/45 backdrop-blur-[25px] sm:fixed sm:top-4 sm:z-50' : 'bg-[#1551A7] sm:mt-4'}`}
      >
        {isMobile ? (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <NavbarBrand className="flex-none" />
              {/* <div className="flex items-center gap-5"> */}
              {/* <UilSearch size="18" color="#1551A7" /> */}
              <div
                class={`tham tham-e-squeeze tham-w-5 ${isOpenHamburgerMenu ? 'tham-active' : ''}`}
                onClick={() =>
                  dispatch(updateIsOpenHamburgerMenu(!isOpenHamburgerMenu))
                }
              >
                <div class="tham-box">
                  <div
                    class={`tham-inner ${location.pathname === '/' ? 'bg-[#1551A7]' : 'bg-white'} `}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
            <div
              className={`pb-4 px-3 ${isOpenHamburgerMenu ? 'flex flex-col gap-3' : 'hidden'}`}
            >
              <SearchInput
                className="mr-auto w-full relative mt-2 mb-1"
                placeholder={'Cari event disini...'}
                iconSize="18"
              />
              {user ? (
                <ul
                  className={`flex flex-col gap-y-2 font-medium font-arOneSans ${location.pathname === '/' ? 'text-black' : 'text-white'} text-sm`}
                >
                  <li className="">
                    <NavLink
                      to="/profile"
                      className="flex items-center gap-x-2"
                    >
                      <UilUser
                        size="15"
                        color={`${location.pathname === '/' ? '#000' : '#FFF'}`}
                      />
                      <h6>Profile</h6>
                    </NavLink>
                  </li>
                  <li
                    className={`pb-3 border-b-[1px] border-solid ${location.pathname === '/' ? 'border-[#000]' : 'border-[#FFF]'}`}
                  >
                    <NavLink
                      to="/my-order"
                      className="flex items-center gap-x-2"
                    >
                      <UilParcel
                        size="15"
                        color={`${location.pathname === '/' ? '#000' : '#FFF'}`}
                      />
                      <h6 className="flex gap-x-2 items-center">
                        <span>My Orders</span>
                        {totalOrders !== 0 && (
                          <span className="text-xs bg-[#FFCB12] rounded-full w-5 h-5 leading-5 text-center">
                            {totalOrders}
                          </span>
                        )}
                      </h6>
                    </NavLink>
                  </li>
                  <li className="" onClick={handleLogoutMobile}>
                    <NavLink className="flex items-center gap-x-2">
                      <UilSignOutAlt
                        size="15"
                        color={`${location.pathname === '/' ? '#000' : '#FFF'}`}
                      />
                      <h6>Logout</h6>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <div className="flex flex-col gap-2">
                  <h3
                    className={`${location.pathname === '/' ? 'text-black' : 'text-white'} font-medium`}
                  >
                    Masuk ke akunmu
                  </h3>
                  <div className="flex gap-3">
                    <NavLink
                      to="/auth"
                      className={`flex justify-center flex-initial w-[50%] py-2 px-6 bg-transparent border-2 rounded-lg ${location.pathname === '/' ? 'text-[#1551A7] border-[#1551A7]' : 'text-white border-white'} font-bold text-sm`}
                    >
                      <button>DAFTAR</button>
                    </NavLink>
                    <NavLink
                      to="/auth"
                      className={`flex justify-center flex-initial w-[50%] py-2 px-6 ${location.pathname === '/' ? 'bg-[#1551A7]' : 'border-2 border-white'} text-white rounded-lg text-sm font-bold`}
                    >
                      <button>MASUK</button>
                    </NavLink>
                  </div>
                </div>
              )}

              {/* <SearchInput
                className="relative"
                placeholder={'Cari event disini...'}
                iconSize="15"
              /> */}
            </div>
          </div>
        ) : (
          <>
            <NavbarBrand className="flex-none" />
            <SearchInput
              className="mr-auto flex-initial w-[600px] relative"
              placeholder={'Cari event disini...'}
              iconSize="18"
            />
            {user ? (
              <NavbarUserMenu
                className="flex-initial w-52"
                totalOrders={totalOrders}
              />
            ) : (
              <div className="">
                <NavbarUserActions
                  handleOpenAuthenticationModal={() =>
                    dispatch(updateIsOpenAuthModal(true))
                  }
                />
              </div>
            )}
          </>
        )}
      </nav>

      <Overlay isOpen={isOpenAuthModal} onClose={handleCloseAuthModal}>
        <div className="bg-white rounded-xl fixed top-20 right-0 bottom-20 left-0 m-auto z-50 pt-11 pb-16 px-10 w-fit h-fit overflow-y-auto">
          {isForgotPassword ? (
            <div className="w-[30rem] pt-4 px-4">
              <p
                tabIndex="0"
                className="focus:outline-none text-2xl font-extrabold leading-6 text-gray-800"
              >
                Masukkan Email
              </p>
              <p
                tabIndex="0"
                className="focus:outline-none text-sm mt-4 font-medium leading-none text-gray-500 mb-4"
              >
                Masukkan email untuk melanjutkan proses
              </p>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={handleSendOTPByEmail}
                validationSchema={ForgotPasswordSchema}
              >
                {(props) => (
                  <form
                    onSubmit={props.handleSubmit}
                    className="flex flex-col gap-y-6 mt-6"
                  >
                    <div className="flex flex-col flex-1 gap-y-2">
                      <label
                        className="font-semibold text-[#000]/40"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                        type="text"
                        value={props.values.email}
                        name="email"
                        id="email"
                        onChange={props.handleChange}
                      />
                      {props.errors.email && props.touched.email ? (
                        <div className="text-xs text-[#D22E2E]">
                          {props.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col gap-y-2 mt-2">
                      <button
                        type="submit"
                        className={`focus:ring-2 focus:ring-offset-2 bg-[#004EA4] text-sm font-semibold leading-none text-white focus:outline-none border py-4 w-full rounded-lg`}
                      >
                        {!isLoadingForgotPassword ? 'Kirim' : 'Loading...'}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          ) : isVerification ? (
            <div className="w-96 pt-4 px-4 w-">
              <p
                tabIndex="0"
                className="focus:outline-none text-2xl font-extrabold leading-6 text-gray-800"
              >
                Masukkan OTP
              </p>
              <p
                tabIndex="0"
                className="focus:outline-none text-sm mt-4 font-medium leading-none text-gray-500 mb-4"
              >
                Masukkan OTP yang dikirimkan melalui email {emailTemp} untuk
                memverifikasi akun
              </p>

              <form
                onSubmit={handleSendOTP}
                className="flex flex-col gap-y-6 mt-6"
              >
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  inputType="number"
                  separator={<span style={{ width: '4rem' }}></span>}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: '1px solid gray',
                    borderRadius: '8px',
                    width: '54px',
                    height: '54px',
                    fontSize: '1.25rem',
                    color: '#000',
                    fontWeight: 'bold',
                    backgroundColor: 'white',
                  }}
                  renderInput={(props) => <input {...props} />}
                  focusStyle={{
                    border: '1px solid #CFD3DB',
                    outline: 'none',
                  }}
                  containerStyle={{
                    gap: '1.5rem',
                    justifyContent: 'center',
                    margin: '1rem 0',
                  }}
                />

                <div className="flex items-center gap-x-2">
                  <div className="flex justify-between w-full">
                    <h6 className="text-sm">Tidak dapat kode?</h6>
                    <button
                      type="button"
                      className={`text-sm font-medium ${
                        isDisableResendOTP ? 'text-slate-400' : 'text-[#004EA4]'
                      }`}
                      onClick={handleResendOTP}
                      disabled={isDisableResendOTP}
                    >
                      Kirim Ulang
                    </button>
                  </div>
                  {isDisableResendOTP && (
                    <Countdown
                      date={Date.now() + 60000}
                      renderer={coundownTimerCompletion}
                    />
                  )}
                </div>
                <div className="flex flex-col gap-y-2 mt-2">
                  <button
                    type="submit"
                    className={`focus:ring-2 focus:ring-offset-2 text-sm font-semibold leading-none text-white focus:outline-none border rounded py-4 w-full rounded-lg ${
                      otp.length < 4
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-[#004EA4]'
                    }`}
                    disabled={otp.length < 4}
                  >
                    {!isLoading ? 'Kirim' : 'Loading...'}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div className="flex flex-col gap-y-6">
              <div className="flex justify-center">
                <img className="w-40" src={brandLogo} alt="Brand Logo" />
              </div>
              <div className="flex justify-between border-b-2 border-solid border-[#00000021]/15% ">
                <button
                  className={`flex-1 h-14 font-semibold ${isLogin ? 'text-[#1551A7] border-b-2 border-solid border-b-[#1551A7]' : ''}`}
                  onClick={() => setIsLogin(!isLogin)}
                >
                  Masuk
                </button>
                <button
                  className={`flex-1 h-14 font-semibold ${!isLogin ? 'text-[#1551A7] border-b-2 border-solid border-b-[#1551A7]' : ''}`}
                  onClick={() => setIsLogin(!isLogin)}
                >
                  Daftar
                </button>
              </div>
              {isLogin ? (
                <div className="flex flex-col gap-y-5">
                  <Formik
                    initialValues={{ phone: '', password: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={SignInSchema}
                  >
                    {(props) => (
                      <form
                        className="flex gap-x-14 items-end"
                        onSubmit={props.handleSubmit}
                      >
                        <div className="flex flex-col gap-y-4 basis-6/12">
                          <div className="flex flex-col flex-1 gap-y-2">
                            <label
                              className="font-semibold text-[#000]/40"
                              htmlFor="phone"
                            >
                              Email / Phone
                            </label>
                            <input
                              className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                              type="text"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.phone}
                              name="phone"
                            />
                            {props.errors.phone && props.touched.phone ? (
                              <div className="text-xs text-[#D22E2E]">
                                {props.errors.phone}
                              </div>
                            ) : null}
                          </div>
                          <div className="flex flex-col flex-1 gap-y-2">
                            <label
                              className="font-semibold text-[#000]/40"
                              htmlFor="password"
                            >
                              Password
                            </label>
                            <input
                              className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                              type="password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.password}
                              name="password"
                            />
                            {props.errors.password && props.touched.password ? (
                              <div className="text-xs text-[#D22E2E]">
                                {props.errors.password}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex flex-col gap-y-4 text-center basis-6/12">
                          <button
                            type="submit"
                            className="bg-[#004EA4] text-white rounded-xl py-2 px-16 font-semibold"
                            // onClick={() => setIsLoggedIn(true)}
                          >
                            Submit
                          </button>
                          <h6 className="text-sm">Or Sign in With</h6>
                          {/* <GoogleLogin
                            width="100%"
                            // size="large"
                            onSuccess={(credentialResponse) => {
                              console.log(
                                jwtDecode(credentialResponse.credential),
                              );
                            }}
                            onError={() => {
                              console.log('Login Failed');
                            }}
                          /> */}

                          <button
                            type="button"
                            className="flex gap-x-3 text-center justify-center bg-transparent border-2 border-solid border-[#1551A7] rounded-xl py-2 px-16 font-semibold"
                            onClick={login}
                          >
                            <img
                              className="w-6"
                              src={iconGoogle}
                              alt="Icon Google"
                            />
                            Sign in With Google
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <h6
                    className="text-[#1551A7] font-semibold cursor-pointer"
                    onClick={() => setIsForgotPassword(true)}
                  >
                    Forgot Password?
                  </h6>
                </div>
              ) : (
                <div>
                  <div className="flex flex-col gap-y-5">
                    {/* <Formik
                      initialValues={}
                      onSubmit={handleSubmit}
                      validationSchema={SignUpSchema}
                    >
                      {(props) => ( */}
                    <form
                      className="flex flex-col gap-y-4"
                      onSubmit={formikSignUp.handleSubmit}
                    >
                      <div className="flex gap-x-14">
                        <div className="flex flex-col flex-1 gap-y-2">
                          <label
                            className="font-semibold text-[#000]/40"
                            htmlFor="fullName"
                          >
                            Full Name
                          </label>
                          <input
                            className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                            type="text"
                            onChange={formikSignUp.handleChange}
                            onBlur={formikSignUp.handleBlur}
                            value={formikSignUp.values.fullName}
                            name="fullName"
                          />
                          {formikSignUp.errors.fullName &&
                          formikSignUp.touched.fullName ? (
                            <div className="text-xs text-[#D22E2E]">
                              {formikSignUp.errors.fullName}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex flex-col flex-1 gap-y-2">
                          <label
                            className="font-semibold text-[#000]/40"
                            htmlFor="password"
                          >
                            Password
                          </label>
                          <input
                            className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                            type="password"
                            onChange={formikSignUp.handleChange}
                            onBlur={formikSignUp.handleBlur}
                            value={formikSignUp.values.password}
                            name="password"
                          />
                          {formikSignUp.errors.password &&
                          formikSignUp.touched.password ? (
                            <div className="text-xs text-[#D22E2E]">
                              {formikSignUp.errors.password}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex gap-x-14">
                        <div className="flex flex-col flex-1 gap-y-2">
                          <label
                            className="font-semibold text-[#000]/40"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <input
                            className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                            type="text"
                            onChange={formikSignUp.handleChange}
                            onBlur={formikSignUp.handleBlur}
                            value={formikSignUp.values.email}
                            name="email"
                          />
                          {formikSignUp.errors.email &&
                          formikSignUp.touched.email ? (
                            <div className="text-xs text-[#D22E2E]">
                              {formikSignUp.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="flex flex-col flex-1 gap-y-2">
                          <label
                            className="font-semibold text-[#000]/40"
                            htmlFor="confirmPassword"
                          >
                            Confirm Password
                          </label>
                          <input
                            className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                            type="password"
                            onChange={formikSignUp.handleChange}
                            onBlur={formikSignUp.handleBlur}
                            value={formikSignUp.values.confirmPassword}
                            name="confirmPassword"
                          />
                          {formikSignUp.errors.confirmPassword &&
                          formikSignUp.touched.confirmPassword ? (
                            <div className="text-xs text-[#D22E2E]">
                              {formikSignUp.errors.confirmPassword}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex gap-x-14 items-end">
                        <div className="flex flex-col flex-1 gap-y-2">
                          <label
                            className="font-semibold text-[#000]/40"
                            htmlFor="numberPhone"
                          >
                            No Handphone
                          </label>
                          <input
                            className="w-96 outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                            type="text"
                            onChange={formikSignUp.handleChange}
                            onBlur={formikSignUp.handleBlur}
                            value={formikSignUp.values.numberPhone}
                            name="numberPhone"
                          />
                          {formikSignUp.errors.numberPhone &&
                          formikSignUp.touched.numberPhone ? (
                            <div className="text-xs text-[#D22E2E]">
                              {formikSignUp.errors.numberPhone}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex flex-col flex-1 gap-y-2">
                          <button
                            type="submit"
                            className="bg-[#004EA4] text-white rounded-xl py-2 px-16 font-semibold"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* )}
                    </Formik> */}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Overlay>
    </>
  );
};

export default Navbar;
