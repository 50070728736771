import instagram from '../../assets/images/instagram-black.png';
import twitter from '../../assets/images/twitter-black.png';
import facebook from '../../assets/images/facebook-black.png';
import whatsapp from '../../assets/images/whatsapp-black.png';
import shareLogo from '../../assets/images/share.png';
import { NavLink } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';

const ShareOption = () => {
  const copyLink = () => {
    toast.success(`Url berhasil disalin`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Slide,
    });
  };

  return (
    <div className="flex flex-col gap-y-1">
      <h6 className="font-semibold">Share</h6>
      <div className="flex items-center gap-x-1">
        {/* <NavLink>
          <img className="w-10" src={instagram} alt="" srcSet="" />
        </NavLink>
        <NavLink>
          <img className="w-8" src={twitter} alt="" srcSet="" />
        </NavLink>
        <NavLink>
          <img className="w-10" src={facebook} alt="" srcSet="" />
        </NavLink> */}
        <a
          href={`whatsapp://send?text=${window.location.href}`}
          data-action="share/whatsapp/share"
          target="_blank"
          rel="noreferrer"
        >
          <img className="w-10" src={whatsapp} alt="" srcSet="" />
        </a>
        <CopyToClipboard text={window.location.href} onCopy={copyLink}>
          <img
            className="w-8 cursor-pointer"
            src={shareLogo}
            alt=""
            srcSet=""
          />
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ShareOption;
