import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  keywordSearch: '',
  foundedEvents: [],
};

export const eventSlice = createSlice({
  name: 'Event Slice',
  initialState,
  reducers: {
    updateKeywordSearch: (state, action) => {
      state.keywordSearch = action.payload;
    },
    updateFoundedEvents: (state, action) => {
      state.foundedEvents = action.payload;
    },
  },
});

export const { updateKeywordSearch, updateFoundedEvents } = eventSlice.actions;
export default eventSlice.reducer;
