import { UilSearch } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  updateFoundedEvents,
  updateKeywordSearch,
} from '../../features/event/eventSlice';
import axios from 'axios';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';

const SearchInput = ({ className, placeholder, iconSize }) => {
  const location = useLocation();
  const keywordSearch = useSelector(
    (state) => state.eventReducer.keywordSearch,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChangeSearch = (event) => {
    dispatch(updateKeywordSearch(event.target.value));
  };

  const handleSearchEvent = async (event) => {
    if (event.keyCode === 13) {
      const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

      try {
        const response = await axios.get(`${BASE_URL}/event`, {
          params: { type: 'all', search: keywordSearch },
        });

        dispatch(updateFoundedEvents(response.data.data));
        dispatch(updateKeywordSearch(''));
        dispatch(updateIsOpenHamburgerMenu(false));
        navigate('/events');
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className={`${className}`}>
      <UilSearch
        className={`absolute left-5 top-0 h-full z-40 ${location.pathname === '/' ? 'fill-[#1551A7]' : 'fill-black/30'}`}
        size={iconSize}
      />
      <input
        className={`border-2 outline-none rounded-3xl py-2 pl-10 text-sm w-full font-arOneSans font-medium sm:pr-5 sm:pl-12 ${location.pathname === '/' ? 'bg-transparent placeholder:text-[#1551A7]  border-[#1551A7]' : 'bg-white border-white placeholder:text-black/30'} `}
        type="text"
        placeholder={placeholder}
        value={keywordSearch}
        onChange={handleChangeSearch}
        onKeyDown={handleSearchEvent}
      />
    </div>
  );
};

export default SearchInput;
