import { useCallback, useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import iconGoogle from '../../../assets/icons/google.png';
import Swal from 'sweetalert2';
import axios from 'axios';
import { getCookie, setCookie } from '../../../helpers/cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateIsOpenAuthModal,
  updateIsOpenHamburgerMenu,
  updateNextUrl,
} from '../../../features/general/generalSlice';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import OTPInput from 'react-otp-input';
import Countdown from 'react-countdown';
import { useGoogleLogin } from '@react-oauth/google';
import { updateTotalOrders } from '../../../features/order/orderSlice';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Format email masih belum benar')
    .matches(/@[^.]*\./, { message: 'Format email masih belum benar' })
    .required('Email harus diisi'),
});

const SignInSchema = Yup.object().shape({
  phone: Yup.string().required('Email atau nomor telepon harus diisi'),
  password: Yup.string().required('Password harus diisi'),
});

const SignUpSchema = Yup.object().shape({
  fullName: Yup.string().required('Nama lengkap harus diisi'),
  numberPhone: Yup.string()
    .min(10, 'Nomor handphone minimal harus 10')
    .max(14, 'Nomor handphone maksimal harus 14')
    .required('Nomor handphone harus diisi'),
  email: Yup.string()
    .email('Format email masih belum benar')
    .matches(/@[^.]*\./, { message: 'Format email masih belum benar' })
    .required('Email harus diisi'),
  password: Yup.string()
    .min(8, 'Password minimal harus 8 karakter')
    .matches(/^[A-Z]/, 'Password harus diawali dengan huruf kapital')
    .required('Password harus diisi'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Konfirmasi password harus sesuai',
  ),
});

const AuthMobile = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isVerification, setIsVerification] = useState(false);
  const [emailTemp, setEmailTemp] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
  const [isDisableResendOTP, setIsDisableResendOTP] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const nextUrl = useSelector((state) => state.generalReducer.nextUrl);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getOrders = useCallback(async () => {
    const user = getCookie('user') && JSON.parse(getCookie('user'));

    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/transaction/tickets`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const results = response.data.data;

      dispatch(updateTotalOrders(results.transactions.length));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (responseToken) => {
      const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';
      const responseUserInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${responseToken.access_token}`,
          },
        },
      );

      const { data } = responseUserInfo;

      try {
        const responseSignInWithGoogle = await axios.post(
          `${BASE_URL}/auth/check-email`,
          {
            email: data.email,
          },
        );

        setCookie('user', JSON.stringify(responseSignInWithGoogle.data.data));
        dispatch(updateIsOpenAuthModal(false));
        navigate(nextUrl ? nextUrl : '/');
        dispatch(updateNextUrl(''));
        getOrders();
      } catch (err) {
        setIsLogin(false);
        formikSignUp.setValues({ email: data.email, fullName: data.name });
      }
    },
    onError: () => {},
  });

  useEffect(() => {
    dispatch(updateIsOpenHamburgerMenu(false));
    window.document.title = 'Authentication - E-Tupay by TunaiPay';
  }, []);

  const handleSubmit = async (values, actions) => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const url = isLogin ? '/auth/login' : '/auth/register';

      const payloads = isLogin
        ? {
            phone: values.phone.trim(),
            password: values.password,
          }
        : {
            email: values.email,
            fullname: values.fullName,
            phone: values.numberPhone,
            password: values.password,
          };

      const response = await axios.post(`${BASE_URL}${url}`, payloads);

      if (!isLogin) {
        setEmailTemp(values.email);
        setIsVerification(true);
      } else {
        const { data } = response.data;
        if (!data.user.email_activated) {
          setIsVerification(true);
          setEmailTemp(values.phone);
        } else {
          setCookie('user', JSON.stringify(response.data.data));
          dispatch(updateIsOpenAuthModal(false));
          navigate(nextUrl ? nextUrl : '/');
          dispatch(updateNextUrl(''));
          getOrders();
        }
      }
    } catch (err) {
      const { data } = err.response;

      Swal.fire({
        title: 'Login Gagal!',
        text: data.message,
        icon: 'error',
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };

  const formikSignUp = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      numberPhone: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSendOTP = async (event) => {
    event.preventDefault();

    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const payloads = { email: emailTemp, otp };
      const response = await axios.post(
        `${BASE_URL}/auth/verify-otp`,
        payloads,
      );

      formikSignUp.setValues({
        fullName: '',
        email: '',
        numberPhone: '',
        password: '',
        confirmPassword: '',
      });

      formikSignUp.handleReset();
      setOtp('');
      setIsVerification(false);
      setCookie('user', JSON.stringify(response.data.data));
      dispatch(updateIsOpenAuthModal(false));
      getOrders();

      Swal.fire({
        title: 'Verifikasi Anda Berhasil!',
        icon: 'success',
        confirmButtonText: 'Ok',
      });

      navigate(nextUrl ? nextUrl : '/');
      dispatch(updateNextUrl(''));
    } catch (err) {
      Swal.fire({
        title: 'Permintaan Gagal!',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  const handleResendOTP = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      setIsDisableResendOTP(true);
      const payloads = { email: emailTemp };
      await axios.post(`${BASE_URL}/auth/resend-otp`, payloads);

      Swal.fire({
        title: 'Permintaan Terkirim!',
        text: 'Segera cek email untuk mendapatkan OTP',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    } catch (err) {
      setIsLoading(false);

      Swal.fire({
        title: 'Permintaan Gagal!',
        text: err.response.data.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  const coundownTimerCompletion = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsDisableResendOTP(false);
      return '';
    } else {
      return (
        <span className="text-sm font-medium">
          ({minutes}:{seconds})
        </span>
      );
    }
  };

  const handleSendOTPByEmail = async (values) => {
    const { email } = values;
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      setIsLoadingForgotPassword(true);
      const payloads = { email };
      const response = await axios.post(
        `${BASE_URL}/auth/forgot-password`,
        payloads,
      );

      setIsLoadingForgotPassword(false);
      setIsForgotPassword(false);

      Swal.fire({
        title: 'Permintaan anda berhasil',
        text: response.data.message,
        icon: 'success',
        showConfirmButton: false,
        timer: 4000,
      });
    } catch (err) {
      setIsLoadingForgotPassword(false);
      Swal.fire({
        title: 'Permintaan Gagal!',
        text: err.data.message,
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <div className="pt-3 pb-12 flex justify-center">
      {isForgotPassword ? (
        <div className="w-[30rem] pt-1 px-4">
          <p
            tabIndex="0"
            className="focus:outline-none text-2xl font-extrabold leading-6 text-gray-800"
          >
            Masukkan Email
          </p>
          <p
            tabIndex="0"
            className="focus:outline-none text-sm mt-4 font-medium leading-none text-gray-500 mb-4"
          >
            Masukkan email untuk melanjutkan proses
          </p>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSendOTPByEmail}
            validationSchema={ForgotPasswordSchema}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                className="flex flex-col gap-y-6 mt-6"
              >
                <div className="flex flex-col flex-1 gap-y-2">
                  <label
                    className="font-semibold text-[#000]/40"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="outline-none bg-transparent px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl"
                    type="text"
                    value={props.values.email}
                    name="email"
                    id="email"
                    onChange={props.handleChange}
                  />
                  {props.errors.email && props.touched.email ? (
                    <div className="text-xs text-[#D22E2E]">
                      {props.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col gap-y-2 mt-2">
                  <button
                    type="submit"
                    className={`focus:ring-2 focus:ring-offset-2 bg-[#004EA4] text-sm font-semibold leading-none text-white focus:outline-none border py-4 w-full rounded-lg`}
                  >
                    {!isLoadingForgotPassword ? 'Kirim' : 'Loading...'}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      ) : isVerification ? (
        <div className="w-96 pt-2 px-4">
          <p
            tabIndex="0"
            className="focus:outline-none font-extrabold text-gray-800"
          >
            Masukkan OTP
          </p>
          <p
            tabIndex="0"
            className="focus:outline-none text-sm mt-4 font-medium leading-none text-gray-500 mb-4"
          >
            Masukkan OTP yang dikirimkan melalui email {emailTemp} untuk
            memverifikasi akun
          </p>

          <form onSubmit={handleSendOTP} className="flex flex-col gap-y-6 mt-6">
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              inputType="number"
              separator={<span style={{ width: '4rem' }}></span>}
              shouldAutoFocus={true}
              inputStyle={{
                border: '1px solid gray',
                borderRadius: '8px',
                width: '54px',
                height: '54px',
                fontSize: '1.25rem',
                color: '#000',
                fontWeight: 'bold',
                backgroundColor: 'transparent',
              }}
              renderInput={(props) => <input {...props} />}
              focusStyle={{
                border: '1px solid #CFD3DB',
                outline: 'none',
              }}
              containerStyle={{
                gap: '1.5rem',
                justifyContent: 'center',
                margin: '1rem 0',
              }}
            />

            <div className="flex items-center gap-x-2">
              <div className="flex justify-between w-full">
                <h6 className="text-sm">Tidak dapat kode?</h6>
                <button
                  type="button"
                  className={`text-sm font-medium ${
                    isDisableResendOTP ? 'text-slate-400' : 'text-[#004EA4]'
                  }`}
                  onClick={handleResendOTP}
                  disabled={isDisableResendOTP}
                >
                  Kirim Ulang
                </button>
              </div>
              {isDisableResendOTP && (
                <Countdown
                  date={Date.now() + 60000}
                  renderer={coundownTimerCompletion}
                />
              )}
            </div>
            <div className="flex flex-col gap-y-2 mt-2">
              <button
                type="submit"
                className={`focus:ring-2 focus:ring-offset-2 text-sm font-semibold leading-none text-white focus:outline-none border rounded py-4 w-full rounded-lg ${
                  otp.length < 4
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-[#004EA4]'
                }`}
                disabled={otp.length < 4}
              >
                {!isLoading ? 'Kirim' : 'Loading...'}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col gap-y-6 w-80">
          <div className="flex justify-between border-b-2 border-solid border-[#00000021]/15% ">
            <button
              className={`flex-1 h-14 font-semibold transition-all ${isLogin ? 'text-[#1551A7] border-b-2 border-solid border-b-[#1551A7]' : ''}`}
              onClick={() => setIsLogin(!isLogin)}
            >
              Masuk
            </button>
            <button
              className={`flex-1 h-14 font-semibold transition-all ${!isLogin ? 'text-[#1551A7] border-b-2 border-solid border-b-[#1551A7]' : ''}`}
              onClick={() => setIsLogin(!isLogin)}
            >
              Daftar
            </button>
          </div>
          {isLogin ? (
            <div className="flex flex-col gap-y-5">
              <Formik
                initialValues={{ phone: '', password: '' }}
                onSubmit={handleSubmit}
                validationSchema={SignInSchema}
              >
                {(props) => (
                  <form
                    className="flex flex-col gap-y-6"
                    onSubmit={props.handleSubmit}
                  >
                    <div className="flex flex-col gap-y-4">
                      <div className="flex flex-col flex-1 gap-y-2">
                        <label
                          className="font-semibold text-[#000]/40"
                          htmlFor="phone"
                        >
                          Email / Phone
                        </label>
                        <input
                          className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                          type="text"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.phone}
                          name="phone"
                        />
                        {props.errors.phone && props.touched.phone ? (
                          <div className="text-xs text-[#D22E2E]">
                            {props.errors.phone}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex flex-col flex-1 gap-y-2">
                        <label
                          className="font-semibold text-[#000]/40"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        <input
                          className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                          type="password"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.password}
                          name="password"
                        />
                        {props.errors.password && props.touched.password ? (
                          <div className="text-xs text-[#D22E2E]">
                            {props.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <h6
                        className="text-[#1551A7] font-semibold"
                        onClick={() => setIsForgotPassword(true)}
                      >
                        Forgot Password?
                      </h6>
                    </div>
                    <div className="flex flex-col gap-y-4 text-center w-full">
                      <button
                        type="submit"
                        className="bg-[#004EA4] w-full text-white rounded-xl py-2 font-semibold"
                      >
                        Submit
                      </button>
                      <h6 className="text-sm">Or Sign in With</h6>
                      <button
                        type="button"
                        className="flex gap-x-3 text-center justify-center bg-transparent border-2 border-solid border-[#1551A7] rounded-xl py-2 px-16 font-semibold"
                        onClick={login}
                      >
                        <img
                          className="w-6"
                          src={iconGoogle}
                          alt="Icon Google"
                        />
                        Sign in With Google
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          ) : (
            <div>
              <div className="flex flex-col gap-y-5">
                {/* <Formik
                initialValues={{
                  fullName: '',
                  email: '',
                  numberPhone: '',
                  password: '',
                  confirmPassword: '',
                }}
                onSubmit={handleSubmit}
              >
                {(props) => ( */}
                <form
                  className="flex flex-col gap-y-4"
                  onSubmit={formikSignUp.handleSubmit}
                >
                  <div className="flex flex-col gap-y-3 gap-x-14">
                    <div className="flex flex-col flex-1 gap-y-2">
                      <label
                        className="font-semibold text-[#000]/40"
                        htmlFor="fullName"
                      >
                        Full Name
                      </label>
                      <input
                        className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                        type="text"
                        onChange={formikSignUp.handleChange}
                        onBlur={formikSignUp.handleBlur}
                        value={formikSignUp.values.fullName}
                        name="fullName"
                      />
                      {formikSignUp.errors.fullName &&
                      formikSignUp.touched.fullName ? (
                        <div className="text-xs text-[#D22E2E]">
                          {formikSignUp.errors.fullName}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col flex-1 gap-y-2">
                      <label
                        className="font-semibold text-[#000]/40"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                        type="text"
                        onChange={formikSignUp.handleChange}
                        onBlur={formikSignUp.handleBlur}
                        value={formikSignUp.values.email}
                        name="email"
                      />
                      {formikSignUp.errors.email &&
                      formikSignUp.touched.email ? (
                        <div className="text-xs text-[#D22E2E]">
                          {formikSignUp.errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-3 gap-x-14">
                    <div className="flex flex-col flex-1 gap-y-2">
                      <label
                        className="font-semibold text-[#000]/40"
                        htmlFor="confirmPassword"
                      >
                        No Handphone
                      </label>
                      <input
                        className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                        type="text"
                        onChange={formikSignUp.handleChange}
                        onBlur={formikSignUp.handleBlur}
                        value={formikSignUp.values.numberPhone}
                        name="numberPhone"
                      />
                      {formikSignUp.errors.numberPhone &&
                      formikSignUp.touched.numberPhone ? (
                        <div className="text-xs text-[#D22E2E]">
                          {formikSignUp.errors.numberPhone}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col flex-1 gap-y-2">
                      <label
                        className="font-semibold text-[#000]/40"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                        type="password"
                        onChange={formikSignUp.handleChange}
                        onBlur={formikSignUp.handleBlur}
                        value={formikSignUp.values.password}
                        name="password"
                      />
                      {formikSignUp.errors.password &&
                      formikSignUp.touched.password ? (
                        <div className="text-xs text-[#D22E2E]">
                          {formikSignUp.errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-6 gap-x-14">
                    <div className="flex flex-col flex-1 gap-y-2">
                      <label
                        className="font-semibold text-[#000]/40"
                        htmlFor="confirmPassword"
                      >
                        Confirm Password
                      </label>
                      <input
                        className="w-[100%] outline-none px-3 py-2 border-dashed border-2 border-[#0B3568] rounded-xl bg-transparent"
                        type="password"
                        onChange={formikSignUp.handleChange}
                        onBlur={formikSignUp.handleBlur}
                        value={formikSignUp.values.confirmPassword}
                        name="confirmPassword"
                      />
                      {formikSignUp.errors.confirmPassword &&
                      formikSignUp.touched.confirmPassword ? (
                        <div className="text-xs text-[#D22E2E]">
                          {formikSignUp.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col flex-1 gap-y-2">
                      <button
                        type="submit"
                        className="bg-[#004EA4] text-white rounded-xl py-2 px-16 font-semibold"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
                {/* )}
              </Formik> */}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AuthMobile;
