import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import ScrollToTop from './components/ScrollToTop';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleOauthClientId } from './utils/constant';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ScrollToTop>
            <GoogleOAuthProvider clientId={googleOauthClientId}>
              <App />
            </GoogleOAuthProvider>
          </ScrollToTop>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
