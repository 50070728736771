import { useCallback, useEffect, useRef, useState } from 'react';
import { UilCalender, UilMapMarker } from '@iconscout/react-unicons';
import eventCard from '../../assets/images/event-card.png';
import fallbackNoImage from '../../assets/images/default-banner.png';
import { NavLink } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import LightGalleryComponent from '../CustomLightGallery';
import { capitalizeText } from '../../helpers/text';
// import LightGallery from 'lightgallery/lightgallery';

const EventCard = ({
  isActive = true,
  poster,
  name,
  date,
  city,
  eventId,
  documentations = [],
  // setDocumentations,
  // lightGallery,
}) => {
  const lightGallery = useRef(null);
  const [images, setImages] = useState(documentations);

  const handleFormatDocumentations = async () => {
    try {
      const formattedDocumentations = await Promise.all(documentations);

      setImages(formattedDocumentations);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    handleFormatDocumentations();
    return () => {};
  }, []);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <>
      <div
        className={`flex flex-col w-[180px] max-[376px]:w-[100%] sm:w-[300px] bg-white rounded-xl sm:rounded-[25px] overflow-hidden font-arOneSans h-fit`}
      >
        <div
          className={`w-full h-[150px] sm:h-[268px] ${poster ? '' : 'flex justify-center'}`}
        >
          <img
            className={`w-full h-full object-cover rounded-xl sm:rounded-[25px]`}
            src={poster || fallbackNoImage}
            alt="Event Card"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = fallbackNoImage;
            }}
          />
        </div>
        {(name || date || city) && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-3 px-3 sm:px-4 pt-2 sm:pt-3 pb-3 sm:pb-6">
              <div className="text-[#0C376B] font-bold">
                <h4 className="text-sm sm:text-lg capitalize">
                  {name && capitalizeText(name)}
                </h4>
              </div>
              <div className="flex flex-col gap-2 font-semibold">
                <div className="flex gap-x-2 items-center">
                  <div>
                    <UilCalender size="18" color="#434343" />
                  </div>
                  <p className="text-[#0C376B]/80 text-[12px] sm:text-base">
                    {date}
                  </p>
                </div>
                <div className="flex gap-x-2 items-center">
                  <div>
                    <UilMapMarker size="18" color="#434343" />
                  </div>
                  <p className="text-[#0C376B]/80 text-[12px] sm:text-base capitalize">
                    {city}
                  </p>
                </div>
              </div>
              {/* <div>
            <h4 className="font-bold text-[#0C376B]">Rp {price}</h4>
          </div> */}
            </div>
            <div className="text-center">
              {isActive ? (
                <NavLink to={`/event/${eventId}`}>
                  <button
                    className={`w-full text-sm sm:text-base ${isActive ? 'hover:bg-[#14a12a] bg-[#15A72C]' : 'bg-[#0B3568] hover:bg-[#092e5b]'} py-4 text-white font-bold transition-colors`}
                    // disabled={!isActive}
                  >
                    BELI TIKET
                  </button>
                </NavLink>
              ) : (
                <button
                  className={`w-full text-xs sm:text-base bg-[#0B3568] hover:bg-[#092e5b] py-4 text-white font-bold transition-colors`}
                  onClick={() => {
                    lightGallery.current.openGallery(0);
                  }}
                  disabled={documentations.length === 0}
                >
                  {documentations.length === 0
                    ? 'BELUM ADA DOKUMENTASI'
                    : 'LIHAT'}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {/* <LightGallery
                plugins={[lgZoom]}
                elementClassNames="custom-class-name"
                onInit={onInit}
            >
                {getItems()}
            </LightGallery> */}
      {/* <LightGalleryComponent galleryRef={galleryRef} /> */}
      {/* {eventDocumentations.length !== 0 && ( */}
      {/* <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}> */}
      {/* <div className="flex mt-3 gap-x-3"> */}
      {/* {documentations.map((documentation) => {
          return (
            <a key={documentation.event_id} href={documentation.caption}>
              <img
                className="rounded"
                alt={documentation.path}
                src={documentation.caption}
                style={{ maxWidth: '5rem' }}
              />
            </a>
          );
        })} */}
      {/* </div> */}
      {/* </LightGallery> */}
      {/* )} */}
      {/* {lightGallery.current && ( */}
      <div style={{ display: 'none' }}>
        <LightGallery
          onInit={onInit}
          elementClassNames={'gallery'}
          dynamic={true}
          plugins={[lgZoom, lgThumbnail]}
          dynamicEl={images}
        ></LightGallery>
      </div>
      {/* )} */}
    </>
  );
};

export default EventCard;
