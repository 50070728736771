import { UilCalender, UilMapMarker } from '@iconscout/react-unicons';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import EventDescriptionCard from '../../components/EventDescriptionCard';
import detailEventImg from '../../assets/images/detail-event-img.png';
import imgThumbEvent from '../../assets/images/image-thumb-event.png';
import ShareOption from '../../components/ShareOption';
import TicketCounter from '../../components/TicketCounter';
import TotalPriceSummary from '../../components/TotalPriceSummary';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { useEffect, useState } from 'react';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import { getCookie } from '../../helpers/cookie';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { convertISOToDate, isDateMissed } from '../../helpers/date';
import { convertNumberToCurrency } from '../../helpers/number';
import './index.css';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { capitalizeText } from '../../helpers/text';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';
import fallbackNoImage from '../../assets/images/default-banner.png';

const DetailEvent = () => {
  const [detailEvent, setDetailEvent] = useState({});
  const [isLoadingDetailEvent, setIsLoadingDetailEvent] = useState(true);
  const [packages, setPackages] = useState([]);
  // const [counter, setCounter] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  let { eventId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = 'Detail Event - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#EBEBEB]'));
  }, [dispatch]);

  useEffect(() => {
    getDetailEvent();
    return () => {};
  }, []);

  const getDetailEvent = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      setIsLoadingDetailEvent(true);
      const response = await axios.get(`${BASE_URL}/event/${eventId}`, {
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });
      const results = response.data.data;
      const formattedPackages = results.packages.map((p) => ({
        ...p,
        qty: 0,
      }));

      setPackages(formattedPackages);
      setDetailEvent(results);
      setIsLoadingDetailEvent(false);
    } catch (err) {
      setIsLoadingDetailEvent(false);
      console.log(err);
    }
  };

  const handleTicketQuantity = (packageId, type) => {
    const packageIdx = packages.findIndex((p) => p.id === packageId);
    let price = totalPrice;

    if (type === 'increment') {
      if (packages[packageIdx].qty >= packages[packageIdx].stock) {
        toast.error(
          `Stok tiket ${packages[packageIdx].name} hanya tersisa ${packages[packageIdx].stock}`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Slide,
          },
        );
      } else if (packages[packageIdx].qty > 4) {
        toast.error(`Hanya bisa membeli tiket maksimal 5 tiket`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Slide,
        });
      } else {
        packages[packageIdx].qty += 1;
        price += Number(packages[packageIdx].price);
      }
    } else {
      if (packages[packageIdx].qty !== 0) {
        packages[packageIdx].qty -= 1;
        price -= Number(packages[packageIdx].price);
      }
    }

    setTotalPrice(price);
    setPackages([...packages]);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />

      <div className="container flex p-[15px] sm:p-0 flex-col sm:flex-row m-auto sm:mt-8 gap-x-8 font-arOneSans">
        {isLoadingDetailEvent ? (
          <CircularProgress className="m-auto" />
        ) : (
          <>
            <div className="flex-initial sm:w-[70%] flex flex-col gap-y-5">
              {isMobile && (
                <div>
                  <img
                    className="w-full rounded-xl"
                    src={detailEvent.medias?.[0]?.path || fallbackNoImage}
                    alt="Detail Event"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = fallbackNoImage;
                    }}
                  />
                  <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
                    {detailEvent.medias?.map((media) => {
                      return (
                        <a key={media.id} href={media.path}>
                          <img
                            className="rounded-md h-full"
                            alt={detailEvent.title}
                            src={media.path}
                            style={{ maxWidth: '5rem' }}
                          />
                        </a>
                      );
                    })}
                  </LightGallery>
                </div>

                // <div>
                //   <img
                //     className="w-full rounded-lg"
                //     src={detailEvent.medias?.[0]?.path}
                //     alt="Detail Event"
                //   />
                // </div>
              )}
              <div className="flex flex-col gap-y-3">
                <div>
                  <h1 className="text-2xl sm:text-3xl font-bold">
                    {detailEvent.title && capitalizeText(detailEvent.title)}
                  </h1>
                </div>
                <div className="flex flex-col flex-wrap sm:flex-row gap-y-2 sm:gap-x-6">
                  <div className="flex gap-x-2 items-center">
                    <div>
                      <UilCalender size="20" color="#434343" />
                    </div>
                    <h3 className="font-semibold text-[#0C376BCC]/80">
                      {detailEvent.start_date &&
                        convertISOToDate(detailEvent.start_date)}
                    </h3>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <div>
                      <UilMapMarker size="20" color="#434343" />
                    </div>
                    <div className="flex flex-wrap gap-x-2">
                      <h3 className="font-semibold text-[#0C376BCC]/80 capitalize">
                        {detailEvent.location}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {detailEvent.descriptions?.map((description, idx) => (
                <EventDescriptionCard
                  key={idx}
                  title={description.title}
                  content={description.content}
                />
              ))}
              {/* <EventDescriptionCard title="Line Up" content={detailEvent.content} />
        <EventDescriptionCard
          title="Tentang Tiket"
          content={detailEvent.content}
        />
        <EventDescriptionCard
          title="Tentang Penukaran Tiket"
          content={detailEvent.content}
        /> */}
              <div className="flex flex-col gap-y-2">
                <h3 className="font-bold capitalize">Lokasi Event</h3>
                <div className="google-map-code rounded-lg overflow-hidden">
                  <iframe
                    src={`https://maps.google.com/maps?q=${detailEvent.lat},${detailEvent.lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                    width="100%"
                    height="450"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-5 flex-initial sm:w-[30%]">
              {!isMobile && (
                <div>
                  <img
                    className="w-full rounded-xl"
                    src={detailEvent.medias?.[0]?.path || fallbackNoImage}
                    alt="Detail Event"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = fallbackNoImage;
                    }}
                  />
                  <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
                    {detailEvent.medias?.map((media) => {
                      return (
                        <a key={media.id} href={media.path}>
                          <img
                            className="rounded-md h-full"
                            alt={detailEvent.title}
                            src={media.path}
                            style={{ maxWidth: '5rem' }}
                          />
                        </a>
                      );
                    })}
                  </LightGallery>
                </div>
              )}
              <div className="mt-4 sm:mt-0">
                <ShareOption />
              </div>
              <div className="flex flex-col gap-y-4">
                {packages?.map((p) => {
                  return (
                    <TicketCounter
                      key={p.id}
                      packageId={p.id}
                      title={p.name}
                      stock={p.stock}
                      price={convertNumberToCurrency(p.price, 'id-ID')}
                      isOutOfStock={
                        p.stock < 1 || p.end_date
                          ? isDateMissed(p.end_date)
                          : isDateMissed(p.start_date)
                      }
                      value={p.qty}
                      handleTicketQuantity={handleTicketQuantity}
                    />
                  );
                })}
                {/* <TicketCounter title="REGULER DAY 2" price="175,000" />
          <TicketCounter title="REGULER 2 DAY PASS" price="300,000" />
          <TicketCounter
            title="PRE-SALE 2 DAY PASS"
            price="250,000"
            isOutOfStock={true}
          /> */}
                <TotalPriceSummary
                  selectedEvent={detailEvent}
                  title="TOTAL"
                  price={convertNumberToCurrency(totalPrice, 'id-ID')}
                  packages={packages}
                  eventId={eventId}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailEvent;
