const EventSection = ({ title, subtitle, children }) => {
  return (
    <section className="container px-3 sm:px-0 flex flex-col gap-y-5 m-auto">
      <div className="px-1 sm:px-4">
        <div className="font-arOneSans">
          <h1 className="text-xl sm:text-3xl font-semibold text-[#192123]">
            {title}
          </h1>
          <p className="text-sm sm:text-base text-[#293235] font-medium">
            {subtitle}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap gap-y-2 gap-x-2 sm:gap-5 justify-start">
        {children}
      </div>
    </section>
  );
};

export default EventSection;
