import { useEffect, useState } from 'react';
import { getCookie } from '../../helpers/cookie';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';

const Profile = () => {
  const [profile, setProfile] = useState({});
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = 'Profile - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  useEffect(() => {
    getProfile();
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#FFF]'));
  }, [dispatch]);

  const getProfile = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/profile`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setProfile(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container m-auto px-3 mt-4 sm:mt-8 font-arOneSans">
      <h3 className="font-bold text-lg">Profile</h3>
      <div className="flex flex-col gap-y-1 mt-2 font-semibold">
        <div className="flex justify-between">
          <h4 className="text-[#192123]/50">Nama:</h4>
          <h4>{profile.fullname}</h4>
        </div>
        <div className="flex justify-between">
          <h4 className="text-[#192123]/50">No Handphone:</h4>
          <h4>{profile.phone}</h4>
        </div>
        <div className="flex justify-between">
          <h4 className="text-[#192123]/50">Email:</h4>
          <h4>{profile.email}</h4>
        </div>
      </div>
    </div>
  );
};

export default Profile;
