import { useEffect } from 'react';
import Carousel from '../../components/Carousel';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Main from '../../components/Main';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';

const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <Main className="sm:relative">{children}</Main>
      <Footer />
    </>
  );
};

export default MainLayout;
