import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = 'Privacy Policy - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  return (
    <div
      className="basis-9/12"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            px: 3,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.75rem',
          }}
        >
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const PrivacyPolicy = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#FFF]'));
  }, [dispatch]);

  return (
    <>
      <div className="container m-auto mt-2 sm:mt-8 font-arOneSans">
        <Box
          sx={
            isMobile
              ? { bgcolor: 'background.paper' }
              : {
                  flexGrow: 1,
                  bgcolor: 'background.paper',
                  display: 'flex',
                }
          }
        >
          <Tabs
            className={`${!isMobile ? 'basis-2/12' : 'mb-6 w-full'}`}
            orientation={`${!isMobile ? 'vertical' : ''}`}
            variant="scrollable"
            value={value}
            scrollButtons={isMobile}
            allowScrollButtonsMobile={isMobile}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab
              label="Perolehan dan Pengumpulan Data Pribadi Pengguna"
              {...a11yProps(0)}
            />
            <Tab label="Penggunaan Data Pribadi" {...a11yProps(1)} />
            <Tab
              label="Pengungkapan Data Pribadi Pengguna "
              {...a11yProps(2)}
            />
            <Tab
              label="Pengaduan terkait Perlindungan Data Pengguna"
              {...a11yProps(3)}
            />
            {/* <Tab label="Item Five" {...a11yProps(4)} />
            <Tab label="Item Six" {...a11yProps(5)} />
            <Tab label="Item Seven" {...a11yProps(6)} /> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            <h6 className="font-semibold text-lg">
              Perolehan dan Pengumpulan Data Pribadi Pengguna
            </h6>
            <p className="text-justify">
              Tunaipay mengumpulkan Data Pribadi Pengguna dengan tujuan untuk
              memproses transaksi Pengguna, mengelola dan memperlancar proses
              penggunaan Situs, serta tujuan-tujuan lainnya selama diizinkan
              oleh peraturan perundang-undangan yang berlaku. Adapun data
              Pengguna yang dikumpulkan adalah sebagai berikut:
            </p>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Data yang diserahkan secara mandiri oleh Pengguna, termasuk
                namun tidak terbatas pada data yang diserahkan pada saat
                Pengguna:
                <ol className="list-decimal pl-4">
                  <li>
                    Membuat atau memperbarui akun Tunaipay, termasuk diantaranya
                    nama pengguna (username), alamat email, nomor telepon,
                    password, alamat, foto, dan informasi lainnya yang dapat
                    mengidentifikasi Pengguna;
                  </li>
                  <li>
                    Melakukan verifikasi toko, termasuk diantaranya Kartu Tanda
                    Penduduk (KTP) dan/atau Nomor Pokok Wajib Pajak (NPWP).
                  </li>
                  <li>
                    Menghubungi Tunaipay, termasuk melalui layanan pelanggan
                    (customer service).
                  </li>
                  <li>
                    Mengisi survei yang dikirimkan oleh Tunaipay atau pihak lain
                    yang ditunjuk secara resmi untuk mewakili Tunaipay
                  </li>
                  <li>
                    Melakukan interaksi dengan Pengguna lainnya melalui fitur
                    pesan, diskusi produk, ulasan, rating, Pusat Resolusi
                    (sebagaimana didefinisikan dalam Syarat dan Ketentuan) dan
                    fitur interaktif (baik satu atau dua arah) lainnya yang
                    terdapat dalam Situs
                  </li>
                  <li>
                    Mempergunakan layanan-layanan pada Situs, termasuk data
                    transaksi yang detil, diantaranya jenis, jumlah dan/atau
                    keterangan dari produk atau layanan yang dibeli, alamat
                    pengiriman, kanal pembayaran yang digunakan, jumlah
                    transaksi, tanggal dan waktu transaksi, serta detil
                    transaksi lainnya
                  </li>
                  <li>
                    Mengisi data-data pembayaran pada saat Pengguna melakukan
                    aktivitas transaksi pembayaran melalui Situs, termasuk namun
                    tidak terbatas pada data rekening bank, kartu kredit,
                    virtual account, instant payment, internet banking, gerai
                    ritel; dan/atau{' '}
                  </li>
                  <li>
                    Mengisi data-data detail mengenai alamat pengiriman (untuk
                    Pembeli), alamat penjemputan dan lokasi toko (untuk
                    Penjual), termasuk namun tidak terbatas pada data alamat
                    lengkap, titik koordinat lokasi berupa longitude latitude,
                    nomor telepon, dan nama yang tercantum saat melakukan
                    penyimpanan data di Tunaipay.
                  </li>
                  <li>
                    Menggunakan fitur pada Situs yang membutuhkan izin akses ke
                    data yang relevan yang tersimpan dalam perangkat Pengguna.
                  </li>
                </ol>
              </li>
              <li>
                Data yang terekam pada saat Pengguna mempergunakan Situs,
                termasuk namun tidak terbatas pada:
                <ol className="list-decimal pl-4">
                  <li>
                    Data lokasi riil atau perkiraannya seperti alamat IP, lokasi
                    Wi-Fi dan geo location
                  </li>
                  <li>
                    Data berupa waktu dari setiap aktivitas Pengguna sehubungan
                    dengan penggunaan Situs, termasuk waktu pendaftaran, login
                    dan transaksi
                  </li>
                  <li>
                    Data penggunaan atau preferensi Pengguna, diantaranya
                    interaksi Pengguna dalam menggunakan Situs, pilihan yang
                    disimpan, serta pengaturan yang dipilih. Data tersebut
                    diperoleh menggunakan cookies, pixel tags, dan teknologi
                    serupa yang menciptakan dan mempertahankan pengenal unik
                  </li>
                  <li>
                    Data perangkat, diantaranya jenis perangkat yang digunakan
                    untuk mengakses Situs, termasuk model perangkat keras,
                    sistem operasi dan versinya, perangkat lunak, nomor IMEI,
                    nama file dan versinya, pilihan bahasa, pengenal perangkat
                    unik, pengenal iklan, nomor seri, informasi gerakan
                    perangkat, dan/atau informasi jaringan seluler; dan/atau
                  </li>
                  <li>
                    Data catatan (log), diantaranya catatan pada server yang
                    menerima data seperti alamat IP perangkat, tanggal dan waktu
                    akses, fitur aplikasi atau laman yang dilihat, proses kerja
                    aplikasi dan aktivitas sistem lainnya, jenis peramban
                    (browser), dan/atau situs atau layanan pihak ketiga yang
                    Pengguna gunakan sebelum berinteraksi dengan Situs
                  </li>
                </ol>
              </li>
              <li>
                Data yang diperoleh dari sumber lain, termasuk namun tidak
                terbatas pada:
                <ol className="list-decimal pl-4">
                  <li>
                    Data berupa geo-location (GPS) dari mitra usaha Tunaipay
                    yang turut membantu Tunaipay dalam mengembangkan dan
                    menyajikan layanan-layanan dalam Situs kepada Pengguna,
                    antara lain mitra penyedia layanan pembayaran, logistik atau
                    kurir, infrastruktur situs, dan mitra- mitra lainnya
                  </li>
                  <li>
                    Data berupa email, nomor telepon, nama, gender, dan/atau
                    tanggal lahir dari mitra usaha Tunaipay tempat Pengguna
                    membuat atau mengakses akun Tunaipay, seperti layanan media
                    sosial, atau situs/aplikasi yang menggunakan application
                    programming interface (API) Tunaipay atau yang digunakan
                    Tunaipay
                  </li>
                  <li>
                    Data dari penyedia layanan finansial, termasuk namun tidak
                    terbatas pada lembaga atau biro pemeringkat kredit atau
                    Lembaga Pengelola Informasi Perkreditan (LPIP)
                  </li>
                  <li>
                    Data dari penyedia layanan finansial (apabila Pengguna
                    menggunakan fitur spesifik seperti mengajukan pinjaman
                    melalui Situs/Aplikasi Tunaipay); dan/atau{' '}
                  </li>
                  <li>Data berupa email dari penyedia layanan pemasaran.</li>
                </ol>
              </li>
            </ol>
            <p>
              Tunaipay dapat menggabungkan data yang diperoleh dari sumber
              tersebut dengan data lain yang dimilikinya
            </p>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h6 className="font-semibold text-lg">Penggunaan Data Pribadi</h6>
            <p className="text-justify">
              Tunaipay dapat menggunakan Data Pribadi yang diperoleh dan
              dikumpulkan dari Pengguna sebagaimana disebutkan dalam bagian
              sebelumnya untuk hal-hal sebagai berikut:
            </p>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Memproses segala bentuk permintaan, aktivitas maupun transaksi
                yang dilakukan oleh Pengguna melalui Situs, termasuk untuk
                keperluan pengiriman produk kepada Pengguna.
              </li>
              <li>
                Penyediaan fitur-fitur untuk memberikan, mewujudkan, memelihara
                dan memperbaiki produk dan layanan kami, termasuk:
                <ol className="list-decimal pl-4">
                  <li>
                    Menawarkan, memperoleh, menyediakan, atau memfasilitasi
                    layanan marketplace seperti laman beranda, penelusuran,
                    pencarian, iklan TopAds, rekomendasi produk, Tunaipay Feed,
                    asuransi, pembiayaan, pinjaman, maupun produk-produk lainnya
                    melalui Situs
                  </li>
                  <li>
                    Memungkinkan fitur untuk mempribadikan (personalised) akun
                    Tunaipay Pengguna, seperti Keranjang Belanja, Wishlist dan
                    Toko Favorit; dan/atau
                  </li>
                  <li>
                    Melakukan kegiatan internal yang diperlukan untuk
                    menyediakan layanan pada situs/aplikasi Tunaipay, seperti
                    pemecahan masalah software, bug, permasalahan operasional,
                    melakukan analisis data, pengujian, dan penelitian, dan
                    untuk memantau dan menganalisis kecenderungan penggunaan dan
                    aktivitas
                  </li>
                </ol>
              </li>
              <li>
                Membantu Pengguna pada saat berkomunikasi dengan Layanan
                Pelanggan Tunaipay, diantaranya untuk:
                <ol className="list-decimal pl-4">
                  <li>Memeriksa dan mengatasi permasalahan Pengguna;</li>
                  <li>
                    Mengarahkan pertanyaan Pengguna kepada petugas layanan
                    pelanggan yang tepat untuk mengatasi permasalahan
                  </li>
                  <li>
                    Mengawasi dan memperbaiki tanggapan layanan pelanggan
                    Tunaipay
                  </li>
                  <li>
                    Menghubungi Pengguna melalui email, surat, telepon, fax, dan
                    metode komunikasi lainnya, termasuk namun tidak terbatas,
                    untuk membantu dan/atau menyelesaikan proses transaksi
                    maupun proses penyelesaian kendala, serta menyampaikan
                    berita atau notifikasi lainnya sehubungan dengan
                    perlindungan Data Pribadi Pengguna oleh Tunaipay, termasuk
                    kegagalan perlindungan Data Pribadi Pengguna
                  </li>
                  <li>
                    Menggunakan informasi yang diperoleh dari Pengguna untuk
                    tujuan penelitian, analisis, pengembangan dan pengujian
                    produk guna meningkatkan keamanan layanan-layanan pada
                    Situs, serta mengembangkan fitur dan produk baru; dan{' '}
                  </li>
                  <li>
                    Menginformasikan kepada Pengguna terkait produk, layanan,
                    promosi, studi, survei, berita, perkembangan terbaru, acara
                    dan informasi lainnya, baik melalui Situs maupun melalui
                    media lainnya. Tunaipay juga dapat menggunakan informasi
                    tersebut untuk mempromosikan dan memproses kontes dan
                    undian, memberikan hadiah, serta menyajikan iklan dan konten
                    yang relevan tentang layanan Tunaipay dan mitra usahanya.{' '}
                  </li>
                </ol>
              </li>
              <li>
                Melakukan monitoring ataupun investigasi terhadap
                transaksi-transaksi mencurigakan atau transaksi yang terindikasi
                mengandung unsur kecurangan atau pelanggaran terhadap Syarat dan
                Ketentuan atau ketentuan hukum yang berlaku, serta melakukan
                tindakan-tindakan yang diperlukan sebagai tindak lanjut dari
                hasil monitoring atau investigasi transaksi tersebut.
              </li>
              <li>
                Dalam keadaan tertentu, Tunaipay mungkin perlu untuk menggunakan
                ataupun mengungkapkan Data Pribadi Pengguna untuk tujuan
                penegakan hukum atau untuk pemenuhan persyaratan dan kewajiban
                peraturan perundang-undangan yang berlaku, termasuk dalam hal
                terjadinya sengketa atau proses hukum antara Pengguna dan
                Tunaipay, atau dugaan tindak pidana seperti penipuan atau
                pencurian data.
              </li>
              <li>
                Pemenuhan persyaratan dan kewajiban peraturan perundang-undangan
                yang berlaku sehubungan dengan kepentingan perpajakan di
                Indonesia.
              </li>
              <li>
                Memfasilitasi transaksi penggabungan, penjualan aset perusahaan,
                konsolidasi atau restrukturisasi, pembiayaan atau akuisisi yang
                melibatkan Tunaipay.
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h6 className="font-semibold text-lg">
              Pengungkapan Data Pribadi Pengguna
            </h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Tunaipay berkomitmen untuk menjaga kerahasiaan Data Pribadi
                Pengguna yang berada di bawah kendali Tunaipay dan menjamin
                tidak ada pengungkapan, penjualan, pengalihan, distribusi
                dan/atau peminjaman Data Pribadi Pengguna kepada pihak ketiga
                lain, tanpa persetujuan dari Pengguna, kecuali dalam hal-hal
                sebagai berikut:
                <ol className="list-decimal pl-4">
                  <li>
                    Dibutuhkan adanya pengungkapan Data Pribadi Pengguna kepada
                    mitra atau pihak ketiga lain yang membantu Tunaipay dalam
                    menyajikan layanan yang tersedia atau layanan yang di
                    kemudian hari akan tersedia pada Situs dan memproses segala
                    bentuk aktivitas Pengguna dalam Situs, termasuk memproses
                    transaksi, verifikasi pembayaran, promosi, dan pengiriman
                    produk.
                  </li>
                  <li>
                    Tunaipay dapat menyediakan Data Pribadi Pengguna kepada
                    mitra usaha sesuai dengan persetujuan Pengguna untuk
                    menggunakan layanan mitra usaha, termasuk diantaranya
                    aplikasi atau situs lain yang telah saling mengintegrasikan
                    API atau layanannya, atau mitra usaha yang mana Tunaipay
                    telah bekerjasama untuk menyelenggarakan promosi,
                    perlombaan, atau layanan khusus yang tersedia pada Situs,
                    seperti program Tukar Tambah atau Langsung Laku yang
                    diselenggarakan Tunaipay.
                  </li>
                  <li>
                    Tunaipay dapat menyediakan Data Pribadi Pengguna kepada
                    pihak ketiga yang menggunakan dan mengintegrasikan API
                    publik yang disediakan oleh Tunaipay (termasuk namun tidak
                    terbatas pada penyedia layanan mitra usaha Tunaipay) dengan
                    aplikasi atau situs yang dioperasikannya untuk kepentingan
                    penyelesaian transaksi antara Pembeli dan Penjual pada Situs
                    atau tujuan penggunaan Data Pribadi lainnya yang telah
                    disebutkan pada Bagian B Kebijakan Privasi ini.
                  </li>
                  <li>
                    Dibutuhkan adanya komunikasi antara mitra usaha Tunaipay
                    (seperti penyedia logistik, pembayaran, dan penyedia fitur
                    atau fasilitas layanan lainnya) dengan Pengguna dalam hal
                    penyelesaian kendala maupun hal-hal lainnya.
                  </li>
                  <li>
                    Tunaipay dapat menyediakan Data Pribadi Pengguna kepada
                    vendor, konsultan, mitra pemasaran, firma/lembaga riset,
                    atau penyedia layanan sejenis dalam rangka kegiatan
                    pemasaran yang dilakukan oleh pihak ketiga, peningkatan dan
                    pemeliharan kualitas layanan Tunaipay, serta kegiatan
                    publikasi lainnya.
                  </li>
                  <li>
                    Pengguna menghubungi Tunaipay melalui media publik seperti
                    blog, media sosial, dan fitur tertentu pada Situs, yang mana
                    komunikasi antara Pengguna dan Tunaipay tersebut dapat
                    dilihat dan diketahui oleh khalayak ramai.{' '}
                  </li>
                  <li>
                    Tunaipay dapat membagikan Data Pribadi Pengguna kepada anak
                    perusahaan dan afiliasinya untuk membantu memberikan layanan
                    atau melakukan pengolahan data untuk dan atas nama Tunaipay.
                  </li>
                  <li>
                    Tunaipay dapat mengungkapkan dan/atau memberikan Data
                    Pribadi Pengguna kepada pihak-pihak yang berkepentingan
                    dalam rangka pelaksanaan analisis kelayakan kredit Pengguna.
                  </li>
                  <li>
                    Tunaipay mengungkapkan Data Pribadi Pengguna dalam upaya
                    mematuhi kewajiban hukum dan/atau adanya permintaan yang sah
                    dari aparat penegak hukum atau instansi penyelenggara negara
                    yang berwenang.{' '}
                  </li>
                </ol>
              </li>
              <li>
                Sehubungan dengan pengungkapan Data Pribadi Pengguna sebagaimana
                dijelaskan di atas, Tunaipay akan/mungkin perlu mengungkapkan
                Data Pribadi Pengguna kepada penyedia layanan pihak ketiga, agen
                dan/atau afiliasi atau perusahaan terkait Tunaipay, dan/atau
                pihak ketiga lainnya yang berlokasi di luar wilayah Indonesia.
                Meskipun demikian, penyedia layanan pihak ketiga, agen dan/atau
                afiliasi atau perusahaan terkait dan/atau pihak ketiga lainnya
                tersebut hanya akan mengelola dan/atau memanfaatkan Data Pribadi
                Pengguna sehubungan dengan satu atau lebih tujuan sebagaimana
                diatur dalam Kebijakan Privasi ini dan sesuai dengan ketentuan
                peraturan perundangan-undangan yang berlaku.
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <h6 className="font-semibold text-lg">
              Pengaduan terkait Perlindungan Data Pengguna
            </h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Jika Pengguna memiliki kekhawatiran tentang penanganan atau
                perlakuan Tunaipay terhadap Data Pribadi Pengguna atau jika
                Pengguna yakin bahwa privasinya telah dilanggar, Pengguna dapat
                menghubungi Tunaipay melalui layanan pelanggan Tunaipay atau
                kepada kontak Tunaipay yang tercantum dalam Kebijakan Privasi
                ini dengan menjelaskan identitas dan sifat keluhan Pengguna.{' '}
              </li>
              <li>
                Tunaipay akan menyelidiki keluhan Pengguna dan berupaya untuk
                memberikan tanggapan terhadap keluhan tersebut dalam kurun waktu
                yang wajar setelah menerima keluhan yang disampaikan oleh
                Pengguna.
              </li>
            </ol>
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

export default PrivacyPolicy;
