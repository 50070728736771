import axios from 'axios';
import Carousel from '../../components/Carousel';
import EventCard from '../../components/EventCard';
import PopularEventCard from '../../components/PopularEventCard';
import EventSection from '../../components/EventSection';
import Hero from '../../components/Hero';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import { convertISOToDate, convertTimeToDate } from '../../helpers/date';
import { convertNumberToCurrency } from '../../helpers/number';
import { getCookie } from '../../helpers/cookie';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';
import fallbackNoImage from '../../assets/images/default-banner.png';
import { checkImage } from '../../helpers/images';

const Home = () => {
  const dispatch = useDispatch();
  const [allEvents, setAllEvents] = useState([]);
  const [hotEvents, setHotEvents] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [passEvents, setPassEvents] = useState([]);
  const lightGallery = useRef(null);

  useEffect(() => {
    window.document.title = 'Home - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  useEffect(() => {
    dispatch(
      updateCurrentBackground('bg-gradient-to-r from-[#AED3FF] to-[#A2CEFF]'),
    );
  }, [dispatch]);

  useEffect(() => {
    getAllEvents();
    getHotEvents();
    getPassEvents();
    getUpcomingEvent();
  }, []);

  const getAllEvents = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/event`, {
        params: { type: 'all', search: '' },
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });

      setAllEvents(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getHotEvents = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/event`, {
        params: { type: 'hot', search: '' },
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });

      setHotEvents(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPassEvents = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/event`, {
        params: { type: 'pass', search: '' },
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });

      setPassEvents(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getUpcomingEvent = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/event`, {
        params: { type: 'soon', search: '' },
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      });

      setUpcomingEvents(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <>
      <div>
        <Hero />
      </div>
      <div className="mt-3 sm:mt-0">
        <Carousel />
      </div>
      <div className="mt-4 sm:mt-14">
        <EventSection title="Hot Event" subtitle="Event yang sedang populer">
          {hotEvents?.length > 0 ? (
            hotEvents.map((hotEvent) => {
              return (
                <PopularEventCard
                  key={hotEvent.id}
                  poster={hotEvent.medias[0]?.path}
                  name={hotEvent.title}
                  date={convertISOToDate(hotEvent.start_date)}
                  city={hotEvent.city}
                  eventId={hotEvent.id}
                />
              );
            })
          ) : (
            <div className="flex justify-center w-full">
              <h1>Belum ada event</h1>
            </div>
          )}
        </EventSection>
      </div>
      <div className="mt-6 sm:mt-16">
        <EventSection
          title="Events"
          subtitle="Semua event yang sedang berlangsung"
        >
          {allEvents?.length > 0 ? (
            allEvents.map((event) => {
              return (
                <EventCard
                  key={event.id}
                  poster={event.medias[0]?.path}
                  name={event.title}
                  date={convertISOToDate(event.start_date)}
                  city={event.city}
                  eventId={event.id}
                />
              );
            })
          ) : (
            <div className="flex justify-center w-full">
              <h1>Belum ada event</h1>
            </div>
          )}
        </EventSection>
      </div>
      <div className="mt-6 sm:mt-16">
        <EventSection
          title="Upcoming Event"
          subtitle="Semua event yang akan datang"
        >
          {upcomingEvents?.length > 0 ? (
            upcomingEvents.map((upcomingEvent) => {
              return (
                <EventCard
                  key={upcomingEvent.id}
                  poster={upcomingEvent.medias[0]?.path}
                />
              );
            })
          ) : (
            <div className="flex justify-center w-full">
              <h1>Belum ada event</h1>
            </div>
          )}
        </EventSection>
      </div>
      <div className="mt-6 sm:mt-16">
        <EventSection
          title="Pass Event"
          subtitle="Event yang telah berlangsung"
        >
          {passEvents?.length > 0 ? (
            passEvents.map((passEvent) => {
              return (
                <EventCard
                  key={passEvent.id}
                  isActive={false}
                  poster={passEvent.medias[0]?.path}
                  name={passEvent.title}
                  date={convertISOToDate(passEvent.start_date)}
                  city={passEvent.city}
                  eventId={passEvent.id}
                  documentations={passEvent.pass_events.map(async (event) => {
                    const isValidImage = await checkImage(event.path);

                    return {
                      src: isValidImage ? event.path : fallbackNoImage,
                      responsive: isValidImage ? event.path : fallbackNoImage,
                      thumb: isValidImage ? event.path : fallbackNoImage,
                      // html: `<img src="${img.src}" onerror="this.src='${fallbackNoImage}'">`,
                    };
                  })}
                  // setDocumentations={setDocumentations}
                  // lightGallery={lightGallery}
                />
              );
            })
          ) : (
            <div className="flex justify-center w-full">
              <h1>Belum ada event</h1>
            </div>
          )}

          {/* <div className="flex mt-3 gap-x-3"> */}
          {/* {documentations.map((documentation) => {
          return (
            <a key={documentation.event_id} href={documentation.caption}>
              <img
                className="rounded"
                alt={documentation.path}
                src={documentation.caption}
                style={{ maxWidth: '5rem' }}
              />
            </a>
          );
        })} */}
          {/* </div> */}
        </EventSection>
        {/* <LightGallery
          onInit={onInit}
          speed={500}
          plugins={[lgZoom, lgVideo]} */}

        {/* > */}
        {/* {console.log(items)} */}
        {/* {getItems()}
        </LightGallery> */}
      </div>
    </>
  );
};

export default Home;
