import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = 'Term and Condition - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  return (
    <div
      className="basis-9/12"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            px: 3,
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.75rem',
          }}
        >
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const TermAndCondition = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#FFF]'));
  }, [dispatch]);

  return (
    <>
      <div className="container m-auto mt-2 sm:mt-8 font-arOneSans">
        <Box
          sx={
            isMobile
              ? { bgcolor: 'background.paper' }
              : {
                  flexGrow: 1,
                  bgcolor: 'background.paper',
                  display: 'flex',
                }
          }
        >
          <Tabs
            className={`${!isMobile ? 'basis-2/12' : 'mb-6'}`}
            orientation={`${!isMobile ? 'vertical' : ''}`}
            variant="scrollable"
            value={value}
            scrollButtons={isMobile}
            allowScrollButtonsMobile={isMobile}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Definisi" {...a11yProps(0)} />
            <Tab
              label="Akun, Saldo Refund, Saldo Penghasilan, Password dan Keamanan"
              {...a11yProps(1)}
            />
            <Tab label="Transaksi Pembelian" {...a11yProps(2)} />
            <Tab label="Harga" {...a11yProps(3)} />
            <Tab label="Penarikan Dana " {...a11yProps(4)} />
            <Tab label="Ganti Rugi" {...a11yProps(5)} />
            <Tab label="Pilihan Hukum " {...a11yProps(6)} />
            <Tab
              label="Biaya Layanan dan Biaya Jasa Aplikasi"
              {...a11yProps(7)}
            />
          </Tabs>

          <TabPanel value={value} index={0}>
            <h6 className="font-semibold text-lg">Definisi</h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2 break-words">
              <li>
                PT Connexist Indonesia adalah suatu perseroan terbatas yang
                menjalankan kegiatan usaha jasa web portal www.tunaipay.com,
                yakni situs pencarian toko dan Barang yang dijual oleh penjual
                terdaftar. Selanjutnya disebut Tunaipay.
              </li>
              <li>Situs Tunaipay adalah www.tunaipay.com.</li>
              <li>
                Syarat & ketentuan adalah perjanjian antara Pengguna dan
                Tunaipay yang berisikan seperangkat peraturan yang mengatur hak,
                kewajiban, tanggung jawab pengguna dan Tunaipay, serta tata cara
                penggunaan sistem layanan Tunaipay.
              </li>
              <li>
                Pengguna adalah pihak yang menggunakan layanan Tunaipay,
                termasuk namun tidak terbatas pada pembeli, penjual maupun pihak
                lain yang sekedar berkunjung ke Situs Tunaipay.
              </li>
              <li>
                Pembeli adalah Pengguna terdaftar yang melakukan permintaan atas
                Barang yang dijual oleh Penjual di Situs Tunaipay.
              </li>
              <li>
                Penjual adalah Pengguna terdaftar yang melakukan tindakan buka
                toko dan/atau melakukan penawaran atas suatu Barang kepada para
                Pengguna Situs Tunaipay.
              </li>
              <li>
                Barang adalah benda yang berwujud / memiliki fisik Barang yang
                dapat diantar / memenuhi kriteria pengiriman oleh perusahaan
                jasa pengiriman Barang.
              </li>
              <li>
                Saldo Refund adalah fasilitas penampungan sementara atas dana
                milik Pembeli (bukan fasilitas penyimpanan dana), yang
                disediakan Tunaipay untuk menampung pengembalian dana transaksi
                (refund) pembelian Barang, produk digital, dan/atau produk
                keuangan. Dana ini hanya dapat digunakan kembali untuk melakukan
                pembelian pada Situs Tunaipay dan/atau ditarik ke akun bank yang
                terdaftar.
              </li>
              <li>
                Saldo Penghasilan adalah fasilitas penampungan sementara atas
                dana milik Penjual (bukan fasilitas penyimpanan dana), yang
                disediakan Tunaipay untuk menampung dana hasil penjualan Barang,
                produk investasi, dan/atau komisi affiliate pada Situs Tunaipay.
                Dana ini hanya dapat ditarik ke akun bank yang terdaftar dan
                tidak dapat digunakan kembali untuk melakukan pembelian pada
                Situs Tunaipay.
              </li>
              <li>
                Feed adalah fitur pada Situs/Aplikasi Tunaipay yang menampilkan
                konten dari Penjual, KOL, atau pihak lainnya terkait Barang
                tertentu.{' '}
              </li>
              <li>
                Key Opinion Leaders atau KOL adalah pihak yang mempromosikan
                Barang atau Penjual tertentu melalui Feed.
              </li>
              <li>
                Rekening Resmi Tunaipay adalah rekening bersama yang disepakati
                oleh Tunaipay dan para pengguna untuk proses transaksi jual beli
                di Situs Tunaipay. Rekening resmi Tunaipay dapat ditemukan di
                halaman
                https://www.tunaipay.com/help/article/metode-bayar-di-tunaipay.{' '}
              </li>
              <li>
                Internal Dropshipper adalah transaksi yang seluruhnya terjadi di
                ruang lingkup Tunaipay yang dilakukan oleh Penjual dengan
                membeli Barang dari Penjual lainnya dan menjualnya kembali
                melalui Situs Tunaipay.
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h6 className="font-semibold text-lg">
              Akun, Saldo Refund, Saldo Penghasilan, Password dan Keamanan
            </h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Pengguna menyatakan dan menjamin bahwa Pengguna telah berusia
                minimal 18 (delapan belas) tahun atau sudah menikah dan tidak
                berada di bawah perwalian atau pengampuan dan Pengguna secara
                hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada
                Syarat dan Ketentuan ini. Jika Pengguna tidak memenuhi ketentuan
                tersebut namun tetap mengakses atau menggunakan Situs/Aplikasi,
                layanan yang tersedia di Situs/Aplikasi, konten pihak ketiga
                yang berada di Situs/Aplikasi, penawaran di Situs/Aplikasi atau
                metode pembayaran yang berlaku di Situs/Aplikasi, Pengguna
                menyatakan dan menjamin bahwa t indakan Pengguna membuka,
                mengakses atau melakukan aktivitas lain dalam Situs/Aplikasi
                telah disetujui oleh orang tua, wali atau pengampu Pengguna.
                Pengguna secara tegas mengesampingkan setiap hak berdasarkan
                peraturan perundang-undangan yang berlaku untuk membatalkan atau
                mencabut setiap dan seluruh persetujuan yang Pengguna berikan
                berdasarkan Syarat dan Ketentuan ini pada waktu Pengguna
                dianggap telah dewasa secara hukum.
              </li>
              <li>
                Pengguna memahami dan menyetujui bahwa dengan melakukan
                pendaftaran akun pada Situs/Aplikasi, Pengguna akan
                teridentifikasi melalui Profile ID yang terhubung kepada sebuah
                Connexist Profile, di mana Pengguna juga secara sukarela
                memberikan persetujuannya.
              </li>
              <li>
                Tunaipay tidak memungut biaya pendaftaran kepada Pengguna.
              </li>
              <li>
                Pengguna memahami bahwa 1 (satu) nomor telepon hanya dapat
                digunakan untuk mendaftar 1 (satu) akun Pengguna Tunaipay,
                kecuali bagi Pengguna yang telah memiliki beberapa akun dengan 1
                (satu) nomor telepon sebelumnya
              </li>
              <li>
                Pengguna yang telah mendaftar berhak bertindak sebagai:
                <ol className="list-decimal pl-4">
                  <li>Pembeli</li>
                  <li>Penjual, dengan memanfaatkan layanan buka toko. </li>
                </ol>
              </li>
              <li>
                Pengguna yang akan bertindak sebagai Penjual diwajibkan memilih
                pilihan menggunakan layanan buka toko. Setelah menggunakan
                layanan buka toko, Pengguna berhak melakukan pengaturan terhadap
                item-item yang akan diperdagangkan di etalase pribadi Pengguna.
              </li>
              <li>
                Pengguna yang telah melakukan tindakan buka toko diharapkan
                mengunggah konten produk yang akan diperdagangkan dalam jangka
                waktu 90 (sembilan puluh) hari kalender setelah toko berhasil
                dibuka. Apabila dalam jangka waktu 90 (sembilan puluh) hari
                kalender Pengguna masih tidak mengunggah konten produk, maka
                Pengguna menyetujui dan memahami bahwa Tunaipay berhak untuk
                melakukan moderasi dan/atau penutupan toko tanpa pemberitahuan
                sebelumnya.
              </li>
              <li>
                Tunaipay tanpa pemberitahuan terlebih dahulu kepada Pengguna,
                memiliki kewenangan untuk melakukan tindakan yang perlu atas
                setiap dugaan pelanggaran atau pelanggaran Syarat & ketentuan
                dan/atau hukum yang berlaku, yakni tindakan berupa memindahkan
                Barang ke gudang, penghapusan Barang, moderasi toko, penutupan
                toko, pembatalan listing, suspensi akun, dan/atau penghapusan
                akun pengguna.{' '}
              </li>
              <li>
                Tunaipay memiliki kewenangan untuk menutup toko atau akun
                Pengguna baik sementara maupun permanen apabila didapati adanya
                tindakan kecurangan dalam bertransaksi, pelanggaran terhadap
                Syarat dan Ketentuan Tunaipay, termasuk namun tidak terbatas
                pada Kebijakan Penalti Pengguna. Pengguna menyetujui bahwa
                Tunaipay berhak melakukan tindakan lain yang diperlukan terkait
                hal tersebut, termasuk namun tidak terbatas pada menolak
                pengajuan pembukaan toko yang baru apabila ditemukan kesamaan
                data.
              </li>
              <li>
                Pengguna memahami dan menyetujui untuk tidak menggunakan,
                memodifikasi, membongkar, melakukan kegiatan penggandaan,
                menjual kembali dan/atau kegiatan mengeksploitasi lainnya pada
                sistem perangkat lunak atau perangkat keras, jaringan dan/atau
                data Situs/Aplikasi dengan teknologi otomatis atau manual tanpa
                adanya izin dari Tunaipay.{' '}
              </li>
              <li>
                Pengguna menyetujui untuk tidak menggunakan dan/atau mengakses
                sistem Tunaipay secara langsung atau tidak langsung baik
                keseluruhan atau sebagian dengan virus, perangkat lunak, atau
                teknologi lainnya yang dapat mengakibatkan melemahkan, merusak,
                mengganggu atau menghambat, membatasi dan/atau mengambil alih
                fungsionalitas serta integritas dari sistem perangkat lunak atau
                perangkat keras, jaringan, dan/atau data pada Situs/Aplikasi
                Tunaipay.{' '}
              </li>
              <li>
                Pengguna dilarang untuk menciptakan dan/atau menggunakan
                perangkat keras/lunak/fitur dan/atau alat lainnya, termasuk
                namun tidak terbatas pada emulator, robot, macro, crawler
                dan/atau perangkat otomatis yang bertujuan untuk mengakses atau
                menggunakan layanan pada sistem Tunaipay, seperti namun tidak
                terbatas pada : (i) manipulasi data Toko; (ii) membuat banyak
                akun; (iii) memanipulasi perangkat yang bertujuan untuk
                merugikan Tunaipay; (iv) kegiatan perambanan (crawling/scraping)
                atau penyalinan konten; (v) kegiatan otomatisasi dalam
                transaksi, jual beli, promosi,dan lain sebagainya; (vi)
                penambahan produk ke etalase; (vii) mengumpulkan (harvest) atau
                mencuri data pengguna; (viii) melakukan spamming, mengirimkan
                komunikasi elektronik dalam jumlah besar, mengirimkan surat
                berantai; dan/atau (ix) aktivitas lain yang secara wajar dapat
                dinilai sebagai tindakan manipulasi layanan dan sistem.{' '}
              </li>
              <li>
                Tunaipay memiliki kewenangan untuk melakukan penyesuaian jumlah
                transaksi toko, penyesuaian jumlah reputasi, dan/atau melakukan
                proses moderasi/menutup akun Pengguna, jika diketahui atau
                diduga adanya kecurangan oleh Pengguna yang bertujuan
                memanipulasi data transaksi Pengguna demi meningkatkan reputasi
                toko (review dan atau jumlah transaksi). Contohnya adalah
                melakukan proses belanja ke toko sendiri dengan menggunakan akun
                pribadi atau akun pribadi lainnya.
              </li>
              <li>
                Saldo Refund berasal dari pengembalian dana transaksi (refund)
                pembelian Barang, produk digital, dan/atau produk keuangan di
                Situs Tunaipay dan tidak bisa dilakukan penambahan secara
                sendiri (top up).{' '}
              </li>
              <li>
                Saldo Refund dapat digunakan sebagai salah satu metode
                pembayaran atas transaksi pembelian Barang, produk digital,
                dan/atau produk investasi di Situs Tunaipay, dan dapat dilakukan
                penarikan dana (withdrawal) ke rekening bank yang terdaftar pada
                akun Pengguna.{' '}
              </li>
              <li>
                Saldo Penghasilan hanya berasal dari hasil penjualan Barang,
                produk investasi, dan/atau komisi affiliate pada Situs Tunaipay
                dan tidak bisa dilakukan penambahan secara sendiri (top up).{' '}
              </li>
              <li>
                Saldo Penghasilan hanya dapat dilakukan penarikan dana
                (withdrawal) ke rekening bank yang terdaftar pada akun Pengguna,
                dan tidak dapat digunakan sebagai metode pembayaran atas
                transaksi pembelian Barang, produk digital, dan/atau produk
                investasi di Situs Tunaipay, namun dapat digunakan untuk
                fitur-fitur berlangganan yang membantu Penjual, seperti TopAds
                atau promo cashback toko.{' '}
              </li>
              <li>
                Tunaipay memiliki kewenangan untuk melakukan pembekuan Saldo
                Refund dan Saldo Penghasilan Pengguna apabila ditemukan / diduga
                adanya tindak kecurangan dalam bertransaksi dan/atau pelanggaran
                terhadap syarat dan ketentuan Tunaipay.{' '}
              </li>
              <li>
                Penjual dilarang melakukan duplikasi toko, duplikasi produk,
                atau tindakan-tindakan lain yang dapat diindikasikan sebagai
                usaha persaingan tidak sehat.
              </li>
              <li>
                Pengguna memiliki hak untuk melakukan perubahan nama toko
                dan/atau domain toko sesuai dengan kebijakan yang ditentukan
                oleh Tunaipay. Pengguna harus memastikan bahwa perubahan pada
                nama toko dan/atau domain toko telah sesuai dengan yang
                diinginkan dan Pengguna bertanggung jawab secara pribadi atas
                perubahan nama toko dan/atau domain toko yang dilakukan oleh
                Pengguna. Pengguna menyetujui bahwa setiap perikatan dan
                tindakan hukum yang terjadi sebelum perubahan nama toko dan/atau
                domain toko tetap mengikat Pengguna.{' '}
              </li>
              <li>
                Pengguna memiliki hak untuk melakukan perubahan pada nama akun
                sebanyak jumlah kesempatan yang disediakan dan Tunaipay berhak
                merubah jumlah kesempatan perubahan pada nama akun. Pengguna
                harus memastikan bahwa perubahan pada nama akun telah sesuai
                dengan yang diinginkan dan bertanggung jawab secara pribadi atas
                perubahan nama akun yang dilakukan oleh Pengguna. Pengguna
                menyetujui bahwa setiap perikatan yang terjadi sebelum perubahan
                nama tetap mengikat Pengguna.
              </li>
              <li>
                Pengguna bertanggung jawab secara pribadi untuk menjaga
                kerahasiaan akun dan password untuk semua aktivitas yang terjadi
                dalam akun Pengguna.
              </li>
              <li>
                Tunaipay tidak akan meminta username, password maupun kode SMS
                verifikasi atau kode OTP milik akun Pengguna untuk alasan
                apapun, oleh karena itu Tunaipay menghimbau Pengguna agar tidak
                memberikan data tersebut maupun data penting lainnya kepada
                pihak yang mengatasnamakan Tunaipay atau pihak lain yang tidak
                dapat dijamin keamanannya.
              </li>
              <li>
                Pengguna setuju untuk memastikan bahwa Pengguna keluar dari akun
                di akhir setiap sesi dan memberitahu Tunaipay jika ada
                penggunaan tanpa izin atas sandi atau akun Pengguna.
              </li>
              <li>
                Pengguna dengan ini menyatakan bahwa Tunaipay tidak bertanggung
                jawab atas kerugian ataupun kendala yang timbul atas
                penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian
                Pengguna, termasuk namun tidak terbatas pada menyetujui dan/atau
                memberikan akses masuk akun yang dikirimkan oleh Tunaipay
                melalui pesan notifikasi kepada pihak lain melalui perangkat
                Pengguna, meminjamkan akun kepada pihak lain, mengakses link
                atau tautan yang diberikan oleh pihak lain, memberikan atau
                memperlihatkan kode verifikasi (OTP), password atau email kepada
                pihak lain, maupun kelalaian Pengguna lainnya yang mengakibatkan
                kerugian ataupun kendala pada akun Pengguna.{' '}
              </li>
              <li>
                Pengguna memahami dan menyetujui bahwa untuk mempergunakan
                fasilitas keamanan one time password (OTP) maka penyedia jasa
                telekomunikasi terkait dapat sewaktu-waktu mengenakan biaya
                kepada Pengguna dengan nominal sebagai berikut (i) Rp 500
                ditambah Pajak Pertambahan Nilai (PPN) sesuai dengan peraturan
                perundang-undang perpajakan yang berlaku di Indonesia untuk
                Indosat, Tri, XL, Smartfren, dan Esia; (ii) Rp 200 ditambah PPN
                sesuai dengan peraturan perundang-undang perpajakan yang berlaku
                di Indonesia Telkomsel.{' '}
              </li>
              <li>
                Penjual dilarang mempromosikan toko dan/atau Barang secara
                langsung menggunakan fasilitas pesan pribadi, diskusi produk,
                ulasan produk yang dapat mengganggu kenyamanan Pengguna lain.
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h6 className="font-semibold text-lg">Transaksi Pembelian</h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Pembeli wajib bertransaksi melalui prosedur transaksi yang telah
                ditetapkan oleh Tunaipay. Pembeli melakukan pembayaran dengan
                menggunakan metode pembayaran yang sebelumnya telah dipilih oleh
                Pembeli, dan kemudian Tunaipay akan meneruskan dana ke pihak
                Penjual apabila tahapan transaksi jual beli pada sistem Tunaipay
                telah selesai.
              </li>
              <li>
                Saat melakukan pembelian Barang, Pembeli menyetujui bahwa:
                <ol className="list-decimal pl-4">
                  <li>
                    Pembeli bertanggung jawab untuk membaca, memahami, dan
                    menyetujui informasi/deskripsi keseluruhan Barang (termasuk
                    didalamnya namun tidak terbatas pada warna, kualitas,
                    fungsi, dan lainnya) sebelum membuat tawaran atau komitmen
                    untuk membeli.{' '}
                  </li>
                  <li>
                    Pembeli mengakui bahwa warna sebenarnya dari produk
                    sebagaimana terlihat di Situs/Aplikasi Tunaipay tergantung
                    pada monitor komputer dan layar handphone Pembeli. Tunaipay
                    telah melakukan upaya terbaik untuk memastikan warna dalam
                    foto-foto yang ditampilkan pada Situs/Aplikasi Tunaipay
                    muncul seakurat mungkin, tetapi tidak dapat menjamin bahwa
                    penampilan warna pada Situs dan aplikasi Tunaipay akan
                    akurat.
                  </li>
                  <li>
                    Pengguna masuk ke dalam kontrak yang mengikat secara hukum
                    untuk membeli Barang ketika Pengguna membeli suatu barang.
                  </li>
                  <li>
                    Tunaipay tidak mengalihkan kepemilikan secara hukum atas
                    barang-barang dari Penjual kepada Pembeli.{' '}
                  </li>
                </ol>
              </li>
              <li>
                Pembeli memahami dan menyetujui bahwa ketersediaan stok Barang
                merupakan tanggung jawab Penjual yang menawarkan Barang
                tersebut, sehingga ketersediaan stok Barang dapat berubah
                sewaktu-waktu, dengan situasi sebagai berikut:
                <ol className="list-decimal pl-4">
                  <li>
                    Jika stok Barang kosong, maka Penjual dapat menolak pesanan
                    dan pembayaran atas Barang yang bersangkutan dikembalikan
                    kepada Pembeli.
                  </li>
                  <li>
                    Jika Pembeli membeli lebih dari 1 (satu) Barang berbeda dan
                    terdapat stok Barang yang kosong, maka Penjual dapat
                    menerima pesanan Barang sebagian dengan persetujuan Pembeli
                    dan Pembeli akan menerima pengembalian pembayaran sebagian.{' '}
                  </li>
                  <li>
                    Jika Pembeli membeli lebih dari 1 (satu) Barang yang sama
                    dan Penjual tidak dapat memenuhi seluruh pesanan, maka
                    Penjual dapat menerima pesanan Barang sebagian dengan
                    persetujuan Pembeli dan Pembeli akan menerima pengembalian
                    pembayaran sebagian.
                  </li>
                  <li>
                    Penerimaan pesanan Barang sebagian oleh Penjual tidak dapat
                    diproses tanpa persetujuan Pembeli, sehingga jika terdapat
                    perubahan stok Barang, maka Pembeli akan mendapatkan
                    notifikasi melalui Aplikasi Tunaipay dan Pembeli dapat
                    menyetujui atau menolak tawaran Penjual sesuai batas waktu
                    konfirmasi pesanan berdasarkan jenis pengiriman yang
                    dipilih. Jika tidak ada konfirmasi dari Pembeli, maka
                    pesanan akan dibatalkan secara otomatis.{' '}
                  </li>
                  <li>
                    Pembeli memahami dan menyetujui dengan menerima pesanan
                    Barang sebagian, maka pengembalian pembayaran sebagian akan
                    diterima oleh Pembeli ketika transaksi selesai sebagaimana
                    dijelaskan pada poin 22 dan 23 di bawah dst.
                  </li>
                </ol>
              </li>
              <li>
                Pembeli memahami sepenuhnya dan menyetujui bahwa segala
                transaksi yang dilakukan antara Pembeli dan Penjual selain
                melalui Rekening Resmi Tunaipay dan/atau tanpa sepengetahuan
                Tunaipay (melalui fasilitas/jaringan pribadi, pengiriman pesan,
                pengaturan transaksi khusus diluar situs Tunaipay atau upaya
                lainnya) adalah merupakan tanggung jawab pribadi dari Pembeli.
              </li>
              <li>
                Tunaipay memiliki kewenangan sepenuhnya untuk menolak pembayaran
                tanpa pemberitahuan terlebih dahulu.
              </li>
              <li>
                Pembeli menyetujui dan memahami bahwa dengan menggunakan
                Situs/Aplikasi Tunaipay pada saat Pembeli melakukan transaksi
                pembelian, Tunaipay akan meneruskan data informasi Pembeli
                kepada Penjual.
              </li>
              <li>
                Pembeli yang menggunakan metode pembayaran transfer bank, nilai
                total pembayaran akan ditambahkan kode unik untuk mempermudah
                proses verifikasi. Jika pembayaran tersebut telah diverifikasi,
                maka kode unik akan dikembalikan ke Saldo Refund Pembeli secara
                real time.
              </li>
              <li>
                Pembeli wajib melakukan pembayaran dengan metode pembayaran yang
                dipilih dengan nominal yang sesuai dengan jumlah tagihan beserta
                kode unik (apabila ada) yang tertera pada halaman pembayaran. PT
                Tunaipay tidak bertanggung jawab atas kerugian yang dialami
                Pembeli, apabila Pembeli melakukan pembayaran yang tidak sesuai
                dengan jumlah tagihan yang tertera pada halaman pembayaran.
              </li>
              <li>
                Pembayaran oleh Pembeli wajib dilakukan segera
                (selambat-lambatnya dalam batas waktu 1x24 jam) setelah Pembeli
                melakukan check-out. Jika dalam batas waktu tersebut pembayaran
                belum dilakukan oleh Pembeli, Tunaipay memiliki kewenangan untuk
                membatalkan transaksi tersebut. Pengguna tidak berhak mengajukan
                klaim atau tuntutan atas pembatalan transaksi tersebut.
              </li>
              <li>
                Pembeli disarankan untuk memeriksa dan melakukan pembayaran
                sesuai dengan nominal tagihan yang tertera pada halaman
                pembayaran. Khusus pembayaran melalui manual transfer bank,
                apabila terdapat kekurangan pembayaran, maka akan dicukupi
                dengan mengurangi saldo Tunaipay pada akun Pembeli. Apabila
                saldo Tunaipay pada akun Pembeli juga tidak mencukupi kekurangan
                Pembayaran, Pembeli akan mendapatkan pemberitahuan melalui pesan
                bantuan, chat atau email yang terdaftar di Tunaipay untuk
                melakukan pembayaran kembali senilai kekurangannya hingga batas
                waktu akhir penerimaan pembayaran yang telah ditentukan
                sebelumnya.
              </li>
              <li>
                Pembayaran dengan metode pembayaran transfer bank (verifikasi
                manual) sangat disarankan mengunggah bukti pembayaran pada
                Aplikasi Tunaipay untuk mempermudah proses verifikasi.
              </li>
              <li>
                Pembeli memahami dan menyetujui bahwa masalah keterlambatan
                proses pembayaran dan biaya tambahan yang disebabkan oleh
                perbedaan bank yang Pembeli pergunakan dengan bank Rekening
                resmi Tunaipay adalah tanggung jawab Pembeli secara pribadi.
              </li>
              <li>
                Pengembalian dana dari Tunaipay kepada Pembeli hanya dapat
                dilakukan jika dalam keadaan-keadaan tertentu berikut ini:
                <ol className="list-decimal pl-4">
                  <li>Kelebihan pembayaran dari Pembeli atas harga Barang,</li>
                  <li>
                    Pembeli menyetujui penerimaan Barang sebagian yang
                    ditawarkan oleh Penjual,
                  </li>
                  <li>
                    Masalah pengiriman Barang telah teridentifikasi secara jelas
                    dari Penjual yang mengakibatkan pesanan Barang tidak sampai,
                  </li>
                  <li>
                    Penjual tidak bisa menyanggupi order karena kehabisan stok,
                    perubahan ongkos kirim, maupun penyebab lainnya,
                  </li>
                  <li>
                    Penjual sudah menyanggupi pengiriman order Barang, tetapi
                    setelah batas waktu yang ditentukan ternyata Penjual tidak
                    mengirimkan Barang hingga batas waktu yang telah ditentukan.
                  </li>
                  <li>
                    Penyelesaian permasalahan melalui Pusat Resolusi berupa
                    keputusan untuk pengembalian dana kepada Pembeli atau hasil
                    keputusan dari pihak Tunaipay.
                  </li>
                </ol>
              </li>
              <li>
                Apabila terjadi proses pengembalian dana, maka pengembalian akan
                dilakukan melalui Saldo Refund milik Pengguna yang akan
                bertambah sesuai dengan jumlah pengembalian dana. Jika Pengguna
                menggunakan pilihan metode pembayaran kartu kredit maka
                pengembalian dana akan merujuk pada ketentuan bagian L. Kartu
                Kredit.
              </li>
              <li>
                Pembeli dilarang untuk membagi 1 (satu) transaksi pembelian
                Barang menjadi 2 (dua) transaksi atau lebih secara terpisah
                untuk membagi nilai pembayaran (split payment) dengan tujuan
                memanipulasi metode pembayaran dan/atau mendapatkan manfaat
                lebih yang melanggar Syarat dan Ketentuan, yang dilakukan dengan
                cara apa pun termasuk namun tidak terbatas dengan menghubungi
                Penjual untuk melakukan perubahan harga dan/atau membeli
                beberapa produk untuk membagi jumlah harga dari suatu produk
                yang diinginkan.
              </li>
              <li>
                Pembeli memahami dan menyetujui untuk melepaskan Tunaipay dari
                tanggung jawab apabila terjadi kendala atas transaksi yang
                menggunakan metode split payment, termasuk namun tidak terbatas
                pada kendala transaksi, kendala pembayaran, kendala pengiriman,
                maupun kerusakan dan/atau kehilangan Barang baik sebagian maupun
                seluruhnya.
              </li>
              <li>
                Tunaipay berwenang untuk melakukan tindakan-tindakan yang
                diperlukan apabila diketahui terdapat transaksi pembelian
                menggunakan metode split payment, termasuk namun tidak terbatas
                pada pembatalan transaksi dan/atau pembatalan promo atau
                cashback.
              </li>
              <li>
                Pembeli menyetujui untuk tidak memberitahukan atau menyerahkan
                bukti pembayaran dan/atau data pembayaran kepada pihak lain
                selain Tunaipay. Dalam hal terjadi kerugian akibat pemberitahuan
                atau penyerahan bukti pembayaran dan/atau data pembayaran oleh
                Pembeli kepada pihak lain, maka hal tersebut akan menjadi
                tanggung jawab Pembeli.
              </li>
              <li>
                Pembeli memahami dan menyetujui bahwa Pembeli bertanggungjawab
                untuk menjaga informasi terkait transaksi dan pengiriman melalui
                Situs/Aplikasi Tunaipay. Seluruh tindakan termasuk namun tidak
                terbatas pada kesepakatan yang dilakukan atas transaksi
                pembelian, pengembalian maupun penukaran Barang diluar
                Situs/Aplikasi Tunaipay adalah bukan tanggung jawab Tunaipay,
                sehingga klaim serta kerugian yang timbul akibat tindakan
                tersebut menjadi tanggung jawab masing-masing pihak secara
                pribadi.
              </li>
              <li>
                Pembeli wajib melakukan konfirmasi penerimaan Barang, setelah
                menerima kiriman Barang yang dibeli. Tunaipay memberikan batas
                waktu 2 (dua) hari setelah pengiriman berstatus "terkirim" pada
                sistem Tunaipay, untuk Pembeli melakukan konfirmasi penerimaan
                Barang. Jika dalam batas waktu tersebut tidak ada konfirmasi
                atau klaim dari pihak Pembeli, maka dengan demikian Pembeli
                menyatakan menyetujui dilakukannya konfirmasi penerimaan Barang
                secara otomatis oleh sistem Tunaipay.
              </li>
              <li>
                Setelah adanya konfirmasi penerimaan Barang atau konfirmasi
                penerimaan Barang otomatis, maka dana pihak Pembeli yang
                dikirimkan ke Rekening resmi Tunaipay akan di lanjut kirimkan ke
                pihak Penjual (transaksi dianggap selesai).
              </li>
              <li>
                Pembeli memahami dan menyetujui bahwa setiap klaim yang
                dilayangkan setelah adanya konfirmasi / konfirmasi otomatis
                penerimaan Barang adalah bukan menjadi tanggung jawab Tunaipay.
                Kerugian yang timbul setelah adanya konfirmasi/konfirmasi
                otomatis penerimaan Barang menjadi tanggung jawab Pembeli secara
                pribadi.
              </li>
              <li>
                Pembeli memahami dan menyetujui bahwa setiap masalah pengiriman
                Barang yang disebabkan keterlambatan pembayaran adalah merupakan
                tanggung jawab dari Pembeli.
              </li>
              <li>
                Tunaipay berwenang mengambil keputusan atas
                permasalahan-permasalahan transaksi yang belum terselesaikan
                akibat tidak adanya kesepakatan penyelesaian, baik antara
                Penjual dan Pembeli, dengan melihat bukti-bukti yang ada.
                Keputusan Tunaipay adalah keputusan akhir yang tidak dapat
                diganggu gugat dan mengikat pihak Penjual dan Pembeli untuk
                mematuhinya.
              </li>
              <li>
                Pengguna memahami dan menyetujui jika ditemukan adanya indikasi
                kecurangan, pelanggaran Syarat dan Ketentuan Tunaipay atau
                ketentuan hukum yang berlaku, maka Tunaipay berwenang untuk
                melakukan tindakan-tindakan yang diperlukan termasuk namun tidak
                terbatas pada tindakan pembatasan pilihan metode pembayaran,
                melakukan pembatalan transaksi tanpa pemberitahuan sebelumnya.
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <h6 className="font-semibold text-lg">Harga</h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Harga Barang yang terdapat dalam situs Tunaipay adalah harga
                yang ditetapkan oleh Penjual. Penjual dilarang memanipulasi
                harga barang dengan cara apapun.
              </li>
              <li>
                Penjual dilarang menetapkan harga yang tidak wajar pada Barang
                yang ditawarkan melalui Situs Tunaipay. Tunaipay berhak untuk
                melakukan tindakan berupa memindahkan Barang ke gudang,
                pemeriksaan, penundaan, atau penurunan konten serta tindakan
                lainnya berdasarkan penilaian sendiri dari Tunaipay atas dasar
                penetapan harga yang tidak wajar.
              </li>
              <li>
                Pembeli memahami dan menyetujui bahwa kesalahan keterangan harga
                dan informasi lainnya yang disebabkan tidak terbaharuinya
                halaman situs Tunaipay dikarenakan browser/ISP yang dipakai
                Pembeli adalah tanggung jawab Pembeli.
              </li>
              <li>
                Penjual memahami dan menyetujui bahwa kesalahan ketik yang
                menyebabkan keterangan harga atau informasi lain menjadi tidak
                benar/sesuai adalah tanggung jawab Penjual. Perlu diingat dalam
                hal ini, apabila terjadi kesalahan pengetikan keterangan harga
                Barang yang tidak disengaja, Penjual berhak menolak pesanan
                Barang yang dilakukan oleh pembeli.
              </li>
              <li>
                Pengguna memahami dan menyetujui bahwa setiap masalah dan/atau
                perselisihan yang terjadi akibat ketidaksepahaman antara Penjual
                dan Pembeli tentang harga bukanlah merupakan tanggung jawab
                Tunaipay.
              </li>
              <li>
                Dengan melakukan pemesanan melalui Tunaipay, Pengguna menyetujui
                untuk membayar total biaya yang harus dibayarkan sebagaimana
                tertera dalam halaman pembayaran, yang terdiri dari harga
                barang, ongkos kirim, dan biaya-biaya lain yang mungkin timbul
                dan akan diuraikan secara tegas dalam halaman pembayaran.
                Pengguna setuju untuk melakukan pembayaran melalui metode
                pembayaran yang telah dipilih sebelumnya oleh Pengguna.
              </li>
              <li>
                Batasan harga maksimal satuan untuk Barang yang dapat ditawarkan
                adalah Rp. 100.000.000,-
              </li>
              <li>
                Situs Tunaipay untuk saat ini hanya melayani transaksi jual beli
                Barang dalam mata uang Rupiah
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <h6 className="font-semibold text-lg">Penarikan Dana</h6>
            <ol className="list-decimal pl-4 flex flex-col gap-y-2">
              <li>
                Penarikan dana sesama bank akan diproses dalam waktu 1x24 jam
                hari kerja, sedangkan penarikan dana antar bank akan diproses
                dalam waktu 2x24 jam hari kerja.
              </li>
              <li>
                Untuk penarikan dana dengan tujuan nomor rekening di luar bank
                BCA, Mandiri, dan BNI apabila ada biaya tambahan yang dibebankan
                akan menjadi tanggungan dari Pengguna.
              </li>
              <li>
                Dalam hal ditemukan adanya dugaan pelanggaran terhadap Syarat
                dan Ketentuan Tunaipay, kecurangan, manipulasi atau kejahatan,
                Pengguna memahami dan menyetujui bahwa Tunaipay berhak melakukan
                tindakan pemeriksaan, pembekuan, penundaan dan/atau pembatalan
                terhadap penarikan dana yang dilakukan oleh Pengguna.
              </li>
              <li>
                Pemeriksaan, pembekuan atau penundaan penarikan dana sebagaimana
                dimaksud dalam Poin O. 3 dapat dilakukan dalam jangka waktu
                selama yang diperlukan oleh pihak Tunaipay.
              </li>
            </ol>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <h6 className="font-semibold text-lg">Ganti Rugi</h6>
            <p>
              Pengguna akan melepaskan Tunaipay dari tuntutan ganti rugi dan
              menjaga Tunaipay (termasuk Induk Perusahaan, direktur, dan
              karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum
              yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal
              Anda melanggar Perjanjian ini, penggunaan Layanan Tunaipay yang
              tidak semestinya dan/ atau pelanggaran Anda terhadap hukum atau
              hak-hak pihak ketiga
            </p>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <h6 className="font-semibold text-lg">Pilihan Hukum </h6>
            <p>
              Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan
              hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan
              hukum. Anda setuju bahwa tindakan hukum apapun atau sengketa yang
              mungkin timbul dari, berhubungan dengan, atau berada dalam cara
              apapun berhubungan dengan situs dan/atau Perjanjian ini akan
              diselesaikan secara eksklusif dalam yurisdiksi pengadilan Republik
              Indonesia.
            </p>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <h6 className="font-semibold text-lg">
              Biaya Layanan dan Biaya Jasa Aplikasi{' '}
            </h6>
            <p>
              Biaya Layanan yang dikenakan kepada Pengguna dan/atau Biaya Jasa
              Aplikasi yang dikenakan kepada Pembeli adalah biaya yang dikenakan
              untuk tujuan pemeliharaan sistem dan peningkatan layanan dalam
              bertransaksi melalui Situs/Aplikasi Tunaipay.
            </p>
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

export default TermAndCondition;
