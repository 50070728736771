import { useDispatch, useSelector } from 'react-redux';
import EventSection from '../../components/EventSection';
import { useEffect, useState } from 'react';
import axios from 'axios';
import EventCard from '../../components/EventCard';
import { convertISOToDate } from '../../helpers/date';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';

const Events = () => {
  const foundedEvents = useSelector(
    (state) => state.eventReducer.foundedEvents,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = 'Events - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  return (
    <div className="mt-4 sm:mt-14">
      <EventSection
        title="Pencarian Event"
        subtitle="Beberapa event yang ditemukan"
      >
        {foundedEvents?.length > 0 ? (
          foundedEvents.map((event) => {
            return (
              <EventCard
                key={event.id}
                poster={event.medias[0]?.path}
                name={event.title}
                date={convertISOToDate(event.start_date)}
                city={event.city}
                eventId={event.id}
              />
            );
          })
        ) : (
          <div className="flex justify-center w-full">
            <h1>Event tidak ditemukan</h1>
          </div>
        )}
      </EventSection>
    </div>
  );
};

export default Events;
