import axios from 'axios';
import Navbar from '../Navbar';
import { useCallback, useEffect, useState } from 'react';
import { getCookie } from '../../helpers/cookie';
import { useDispatch, useSelector } from 'react-redux';
import { updateTotalOrders } from '../../features/order/orderSlice';

const Header = () => {
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const dispatch = useDispatch();

  const getOrders = useCallback(async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/transaction/tickets`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const results = response.data.data;

      dispatch(updateTotalOrders(results.transactions.length));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getOrders();
    return () => {};
  }, []);

  return (
    <header className="py-2 sm:py-0 flex justify-center">
      <Navbar />
    </header>
  );
};

export default Header;
