import { useState } from 'react';
import {
  UilCalender,
  UilMapMarker,
  UilImport,
  UilTagAlt,
  UilBill,
} from '@iconscout/react-unicons';
import cardOrderImage from '../../assets/images/order-card-img.png';
import Overlay from '../Overlay';

import brandLogo from '../../assets/images/ticketing-blue.png';
import ticketExample from '../../assets/images/ticket-example.png';
import { convertISOToDate } from '../../helpers/date';
import fallbackNoImage from '../../assets/images/default-banner.png';

const OrderCard = ({
  title,
  poster,
  startDate,
  city,
  paymentStatus,
  paymentLink,
  handleShowDetailTicket,
}) => {
  const [isTicketOpen, setIsTicketOpen] = useState(false);

  return (
    <>
      <div className="py-2 flex gap-x-3 bg-white w-full items-center rounded-xl">
        <img
          className="w-20 h-20 rounded-xl object-cover basis-3/12"
          src={poster || fallbackNoImage}
          alt="Card Order"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackNoImage;
          }}
        />
        <div className="flex w-full flex-col gap-y-2 justify-center basis-7/12">
          <div className="flex flex-col gap-y-1">
            <h4 className="text-sm font-bold capitalize">{title}</h4>
            {/* <h4 className="text-xs font-bold">
              Qty: <span>4</span>
            </h4> */}
          </div>
          <div className="flex justify-between items-end">
            <div className="flex flex-col gap-y-1 font-semibold">
              <div className="flex gap-x-2 items-center">
                <div>
                  <UilCalender size="16" color="#434343" />
                </div>
                <p className="text-[#0C376B]/80 text-[12px]">
                  {startDate && convertISOToDate(startDate)}
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div>
                  <UilMapMarker size="16" color="#434343" />
                </div>
                <p className="text-[#0C376B]/80 text-[12px] capitalize">
                  {city}
                </p>
              </div>
              <div className="flex gap-x-2 items-center">
                <div>
                  <UilBill size="16" color="#434343" />
                </div>
                <p
                  className={`${
                    paymentStatus === 'UNPAID'
                      ? 'text-[#f7872a]'
                      : 'text-[#26c11b]'
                  } text-[12px] capitalize`}
                >
                  {paymentStatus}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-2 basis-2/12">
          <button
            className="text-xs bg-[#1551A7] h-fit py-2 px-3 font-bold text-white rounded-lg"
            onClick={handleShowDetailTicket}
          >
            Detail
          </button>
          {paymentStatus === 'UNPAID' && (
            <button
              className="text-xs bg-[#1551A7] h-fit py-2 px-3 font-bold text-white rounded-lg"
              onClick={() => window.open(paymentLink, '_blank')}
            >
              Bayar
            </button>
          )}
        </div>
      </div>

      <Overlay
        isOpen={isTicketOpen}
        onClose={() => setIsTicketOpen(!isTicketOpen)}
      >
        <div className="bg-white fixed top-5 right-0 bottom-5 left-0 m-auto z-50 py-6 px-10 w-fit overflow-y-auto">
          <div className="flex flex-col gap-y-4">
            <div className="flex pb-2 justify-between border-b-2 border-dashed border-black/15">
              <div className="w-32">
                <img className="w-full" src={brandLogo} alt="Brand Logo" />
              </div>
              <div className="flex gap-x-2 items-center">
                <UilImport size="18" color="#434343" />
                <button className="font-semibold">E-Ticket</button>
              </div>
            </div>
            <div className="flex gap-x-6 pb-6 items-center border-b-2 border-dashed border-black/15s">
              <div className="flex flex-col gap-y-4 ">
                <div className="w-full pb-6 border-b-2 border-dashed border-black/15">
                  <img
                    className="w-60"
                    src={ticketExample}
                    alt="Ticket Example"
                  />
                </div>
                <div className="flex flex-col gap-y-2">
                  <div className="flex items-center gap-x-20">
                    <div>
                      <p className="text-sm">Nomor Transaksi</p>
                      <h6 className="font-semibold">ASAJASA-1092109</h6>
                    </div>
                    <div>
                      <p className="text-sm">Harga</p>
                      <h6 className="font-semibold">ASAJASA-1092109</h6>
                    </div>
                    <div>
                      <p className="text-sm">Lokasi Acara</p>
                      <h6 className="font-semibold">ASAJASA-1092109</h6>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-20">
                    <div>
                      <p className="text-sm">Nama Customer</p>
                      <h6 className="font-semibold">ASAJASA-1092109</h6>
                    </div>
                    <div>
                      <p className="text-sm">Kategori </p>
                      <h6 className="font-semibold">ASAJASA-1092109</h6>
                    </div>
                    <div>
                      <p className="text-sm">Tanggal Acara</p>
                      <h6 className="font-semibold">ASAJASA-1092109</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-full border-l-2 border-solid border-black/15">
                <h1>QR CODE</h1>
              </div>
            </div>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default OrderCard;
