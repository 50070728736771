const Overlay = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen ? (
        <div>
          <div
            className="bg-black/30 backdrop-blur-[2px] w-screen h-screen fixed top-0 left-0 z-50"
            onClick={onClose}
          />
          {children}
        </div>
      ) : null}
    </>
  );
};

export default Overlay;
