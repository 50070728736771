import { NavLink, useNavigate } from 'react-router-dom';
import { removeCookie } from '../../helpers/cookie';

const DropdownNavbarUserMenu = ({ totalOrders }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
    removeCookie('user');
  };

  return (
    <div className="bg-white absolute top-16 z-20 py-3 px-5 w-full shadow-2xl rounded-lg border-2 border-solid border-black/15">
      <ul className="flex flex-col gap-y-2 text-[#0F407B] font-bold">
        <li>
          <NavLink to="/profile">
            <p>Profile</p>
          </NavLink>
        </li>
        <li className="border-b-2 border-solid border-black/15 pb-3">
          <NavLink className="flex justify-between" to="/my-order">
            <p>My Order</p>
            <p>{totalOrders}</p>
          </NavLink>
        </li>
        <li onClick={handleLogout}>
          <p>Log Out</p>
        </li>
      </ul>
    </div>
  );
};

export default DropdownNavbarUserMenu;
