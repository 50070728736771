import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import DetailEvent from './pages/DetailEvent';
import FormOrder from './pages/FormOrder';
import MyOrder from './pages/MyOrder';
import AuthMobile from './pages/Mobile/Auth';
import Profile from './pages/Profile';
import { useSelector } from 'react-redux';
import Events from './pages/Events';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermAndCondition from './pages/TermAndCondition';
import OrderDetail from './pages/OrderDetail';

function App() {
  // const location = useLocation();
  const currentBackgroundColor = useSelector(
    (state) => state.themeReducer.currentBackgroundColor,
  );

  return (
    <div className={currentBackgroundColor}>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/event/:eventId" element={<DetailEvent />} />
          <Route path="/event/:eventId/order" element={<FormOrder />} />
          <Route path="/my-order" element={<MyOrder />} />
          <Route path="/my-order/tickets" element={<OrderDetail />} />
          <Route path="/auth" element={<AuthMobile />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term-and-condition" element={<TermAndCondition />} />
        </Routes>
      </MainLayout>
    </div>
  );
}

export default App;
