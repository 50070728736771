import { NavLink } from 'react-router-dom';
import { UilCalender, UilMapMarker } from '@iconscout/react-unicons';
import fallbackNoImage from '../../assets/images/default-banner.png';
import { capitalizeText } from '../../helpers/text';

const PopularEventCard = ({ poster, name, date, city, eventId }) => {
  return (
    <div className="flex flex-col border-[3px] border-solid border-[#0D3B73] w-[410px] rounded-[30px] overflow-hidden font-arOneSans">
      <div className={`w-full h-[195px]  bg-white`}>
        <img
          className="w-full h-full object-cover"
          src={poster || fallbackNoImage}
          alt="Popular Event Card"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackNoImage;
          }}
        />
      </div>
      <div className="flex flex-col bg-white">
        <div className="flex flex-col px-4 sm:px-5 pt-3 sm:pt-4 pb-6 sm:pb-8 gap-y-2 border-b-2 border-solid border-b-[#1551A7]">
          <div>
            <h2 className="text-[#0C376] font-bold text-lg sm:text-xl text-[#0C376B]">
              {name && capitalizeText(name)}
            </h2>
          </div>
          <div className="flex gap-x-3 text-base text-[#0C376B]/80 font-semibold">
            <div className="flex items-center gap-x-2">
              <div>
                <UilCalender size="18" color="#434343" />
              </div>
              <p className="sm:text-base text-sm">{date}</p>
            </div>
            <div className="flex items-center gap-x-2">
              <div>
                <UilMapMarker size="18" color="#434343" />
              </div>
              <p className="capitalize sm:text-base text-sm">{city}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-sm sm:text-base font-bold text-[#0B3568] items-center">
          {/* <div className="flex-1 text-center">
            <h3>Harga Rp 200.000</h3>
          </div> */}
          <div className="flex-1 bg-[#0B3568] text-center">
            <NavLink to={`/event/${eventId}`}>
              <button className="text-white w-full text-center p-3 hover:bg-[#092e5b] transition-colors">
                BELI TIKET
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularEventCard;
