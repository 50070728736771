import './index.css';

const EventDescriptionCard = ({ title, content }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <h3 className="font-bold capitalize">{title}</h3>
      <div className="desc-wrapper bg-white py-4 px-5 rounded-lg h-auto">
        <p
          className="break-words"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></p>
      </div>
    </div>
  );
};

export default EventDescriptionCard;
