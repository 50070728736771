import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenAuthModal: false,
  nextUrl: '',
  isOpenHamburgerMenu: false,
};

export const generalSlice = createSlice({
  name: 'General Slice',
  initialState,
  reducers: {
    updateIsOpenAuthModal: (state, action) => {
      state.isOpenAuthModal = action.payload;
    },
    updateNextUrl: (state, action) => {
      state.nextUrl = action.payload;
    },
    updateIsOpenHamburgerMenu: (state, action) => {
      state.isOpenHamburgerMenu = action.payload;
    },
  },
});

export const {
  updateIsOpenAuthModal,
  updateNextUrl,
  updateIsOpenHamburgerMenu,
} = generalSlice.actions;
export default generalSlice.reducer;
