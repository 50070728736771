import { useEffect, useState } from 'react';
import { UilCalender, UilMapMarker, UilImport } from '@iconscout/react-unicons';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { updateCurrentBackground } from '../../features/theme/themeSlice';
import myTicketImg from '../../assets/images/my-ticket-img.png';
import Overlay from '../../components/Overlay';
import OrderCard from '../../components/OrderCard';

import brandLogo from '../../assets/images/ticketing-blue.png';
import ticketExample from '../../assets/images/ticket-example.png';
import axios from 'axios';
import { getCookie } from '../../helpers/cookie';
import { convertISOToDate } from '../../helpers/date';
import { Chip } from '@mui/material';
import moment from 'moment';
import { NavLink, useNavigate } from 'react-router-dom';
import { updateSelectedOrder } from '../../features/order/orderSlice';
import { updateIsOpenHamburgerMenu } from '../../features/general/generalSlice';
import fallbackNoImage from '../../assets/images/default-banner.png';

const MyOrder = () => {
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const user = getCookie('user') && JSON.parse(getCookie('user'));
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(updateCurrentBackground('bg-[#FFF]'));
  }, [dispatch]);

  useEffect(() => {
    window.document.title = 'My Orders - E-Tupay by TunaiPay';
    dispatch(updateIsOpenHamburgerMenu(false));
  }, []);

  useEffect(() => {
    getOrders();
    return () => {};
  }, []);

  const getOrders = async () => {
    const BASE_URL = 'https://tunaipay.inovatiftujuh8.com/v1';

    try {
      const response = await axios.get(`${BASE_URL}/transaction/tickets`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const results = response.data.data;

      setOrders(results.transactions);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowDetailTicket = (order) => {
    dispatch(updateSelectedOrder(order));
    navigate('/my-order/tickets');
  };

  return (
    <>
      <div className="container m-auto mt-2 sm:mt-8 font-arOneSans">
        {isMobile ? (
          <div className="px-3">
            <h3 className="font-bold text-lg">My Order</h3>
            <div className="flex flex-col gap-y-3 mt-4">
              {orders.length > 0 ? (
                orders.map((order) => (
                  <div className="flex flex-col">
                    <h6 className="font-bold text-sm">{order.created_at}</h6>
                    <OrderCard
                      handleShowDetailTicket={() =>
                        handleShowDetailTicket(order)
                      }
                      title={order.product.title}
                      poster={order.product.medias[0].path}
                      startDate={order.product.start_date}
                      city={order.product.city}
                      paymentStatus={order.order.status}
                      paymentLink={order.order.url}
                    />
                  </div>
                ))
              ) : (
                <h3>Belum ada data</h3>
              )}
            </div>
          </div>
        ) : (
          <>
            <h3 className="font-bold text-base sm:text-lg mb-6 text-center">
              My Orders
            </h3>
            <table className="w-full border-collapse border-spacing-0">
              <thead>
                <tr className="bg-[#1551A7] text-white">
                  <th className="text-center w-[20%] py-4 px-6 rounded-l-xl">
                    Tanggal Transaksi
                  </th>
                  <th className="text-center w-[40%] py-3 px-5">
                    Detail Event
                  </th>
                  <th className="text-center w-[20%] py-3 px-5">
                    Status Pembayaran
                  </th>
                  <th className="text-center w-[20%] py-3 px-5 rounded-r-xl">
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 ? (
                  orders.map((order) => {
                    return (
                      <tr className="border-2">
                        <td className="text-center px-6 pt-8 pb-6 font-bold">
                          {order.created_at}
                        </td>
                        <td className="text-left py-6">
                          <div className="flex flex-col">
                            <div className="flex gap-x-5">
                              <div className="basis-3/12 w-14">
                                <img
                                  className="w-full h-[120px] object-cover rounded-md"
                                  src={
                                    order.product.medias[0].path ||
                                    fallbackNoImage
                                  }
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = fallbackNoImage;
                                  }}
                                />
                              </div>
                              <div className="flex flex-col gap-y-3 basis-9/12">
                                <h3 className="font-bold capitalize">
                                  {order.product.title}
                                </h3>
                                <div className="flex flex-col gap-y-2">
                                  <div className="flex gap-x-3 items-center">
                                    <div>
                                      <UilCalender size="18" color="#434343" />
                                    </div>
                                    <p className="text-sm font-semibold text-[#0C376BCC]/80">
                                      {order.product.start_date &&
                                        convertISOToDate(
                                          order.product.start_date,
                                        )}
                                    </p>
                                  </div>
                                  <div className="flex gap-x-3 items-center">
                                    <div>
                                      <UilMapMarker size="18" color="#434343" />
                                    </div>
                                    <p className="text-sm font-semibold text-[#0C376BCC]/80 capitalize">
                                      {order.product.city}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="flex flex-col">
                              {generateTickets(order.product.tickets)}
                            </div> */}
                          </div>
                        </td>
                        <td className="text-center p-6 font-bold">
                          <Chip
                            label={order.order.status}
                            color={
                              order.order.status === 'UNPAID'
                                ? 'warning'
                                : 'success'
                            }
                          />
                        </td>
                        <td className="text-center p-6 flex flex-col justify-center gap-y-3 items-center gap-x-2">
                          {/* <NavLink to="/my-order/"> */}
                          <button
                            className="text-sm bg-[#1551A7] py-4 px-10 font-bold text-white rounded-xl"
                            onClick={() => handleShowDetailTicket(order)}
                          >
                            Detail Tiket
                          </button>
                          {/* </NavLink> */}
                          {order.order.status === 'UNPAID' && (
                            <button
                              className="text-sm bg-[#1551A7] py-4 px-10 font-bold text-white rounded-xl"
                              onClick={() =>
                                window.open(order.order.url, '_blank')
                              }
                            >
                              Bayar
                            </button>
                          )}
                          {/* <button
                            className="text-sm bg-[#1551A7] py-3 px-10 font-bold text-white rounded-xl"
                            onClick={
                              order.order.status === 'UNPAID'
                                ? () => (window.location.href = order.order.url)
                                : () => setIsTicketOpen(!isTicketOpen)
                            }
                          >
                            {order.order.status === 'UNPAID'
                              ? 'Bayar'
                              : 'Lihat'}
                          </button> */}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center pt-7">
                      <h5>Belum ada data</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default MyOrder;
