import { UilMinus, UilPlus } from '@iconscout/react-unicons';

const TicketCounter = ({
  packageId,
  title,
  stock,
  price,
  isOutOfStock = false,
  value,
  handleTicketQuantity,
}) => {
  return (
    <div className="w-full flex justify-between items-center border-[3px] border-solid border-[#1551A7] px-4 py-2 rounded-2xl bg-white">
      <div>
        <h6 className="font-bold text-[#1551A7]">{title}</h6>
        <h6 className="font-bold">IDR {price}</h6>
      </div>
      {!isOutOfStock ? (
        <div className="flex gap-x-6 items-center">
          <button
            className={`${value === 0 ? 'bg-gray-400' : 'bg-[#1551A7]'}  rounded-full w-6 h-6 flex items-center justify-center`}
            onClick={() => handleTicketQuantity(packageId, 'decrement')}
          >
            <UilMinus color="#fff" size="14" />
          </button>
          <h6 className="font-bold">{value}</h6>
          <button
            className={`${value >= stock || value === 5 ? 'bg-gray-400' : 'bg-[#1551A7]'}  rounded-full w-6 h-6 flex items-center justify-center`}
            onClick={() => handleTicketQuantity(packageId, 'increment')}
          >
            <UilPlus color="#fff" size="14" />
          </button>
        </div>
      ) : (
        <div>
          <h6 className="bg-red-600 py-2 px-7 text-white font-semibold rounded-3xl">
            Habis
          </h6>
        </div>
      )}
    </div>
  );
};

export default TicketCounter;
