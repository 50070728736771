import { useState } from 'react';
import { UilAngleDown } from '@iconscout/react-unicons';
import { useLocation } from 'react-router-dom';
import { getCookie } from '../../helpers/cookie';
import DropdownNavbarUserMenu from '../DropdownNavbarUserMenu';

const NavbarUserMenu = ({ className, totalOrders }) => {
  const [isOpenDropdownUserMenu, setIsOpenDropdownUserMenu] = useState(false);
  const location = useLocation();
  const user = getCookie('user') && JSON.parse(getCookie('user'));

  return (
    <div
      className={`relative flex justify-end items-center gap-x-1 hover:cursor-pointer ${className}`}
      onClick={() => setIsOpenDropdownUserMenu(!isOpenDropdownUserMenu)}
    >
      <h3
        className={`font-arOneSans font-bold relative ${location.pathname === '/' ? 'text-[#0F407B]' : 'text-white'}`}
      >
        {user.user.email}
        {totalOrders !== 0 && (
          <span className="absolute bg-[#FFCB12] rounded-full top-[-10px] right-[-10px] w-6 h-6 leading-6 text-center">
            {totalOrders}
          </span>
        )}
      </h3>
      <UilAngleDown
        size="28"
        color={location.pathname === '/' ? '#0f407b' : '#fff'}
      />

      {isOpenDropdownUserMenu && (
        <DropdownNavbarUserMenu totalOrders={totalOrders} />
      )}
    </div>
  );
};

export default NavbarUserMenu;
