import moment from 'moment';

export const convertTimeToDate = (time) => {
  const formatter = new Intl.DateTimeFormat('id', { month: 'long' });
  const date = new Date(time);

  return `${date.getDate()} ${formatter.format(date)} ${date.getFullYear()}`;
};

export const convertISOToDate = (isoString) => {
  const formatter = new Intl.DateTimeFormat('id', { month: 'long' });
  const date = new Date(isoString);

  return `${date.getDate()} ${formatter.format(date)} ${date.getFullYear()}`;
};

export const isDateMissed = (date) => {
  return moment(date).isBefore(moment(), 'day');
};
