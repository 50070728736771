import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentBackgroundColor: 'bg-gradient-to-r from-[#AED3FF] to-[#A2CEFF]',
};

export const themeSlice = createSlice({
  name: 'Theme Slice',
  initialState,
  reducers: {
    updateCurrentBackground: (state, action) => {
      state.currentBackgroundColor = action.payload;
    },
  },
});

export const { updateCurrentBackground } = themeSlice.actions;
export default themeSlice.reducer;
