import tunaiPayLogo from '../../assets/images/tunai-pay.png';
import instagram from '../../assets/images/instagram.png';
import tiktok from '../../assets/images/tiktok.svg';
import facebook from '../../assets/images/facebook.png';
import whatsapp from '../../assets/images/whatsapp.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#1551A7] flex flex-col gap-y-8 items-center py-20 mt-12">
      <div>
        <NavLink to="/">
          <img className="w-44" src={tunaiPayLogo} alt="Tunai Pay Logo" />
        </NavLink>
      </div>
      <div className="flex gap-x-2 items-center">
        <a
          href="https://www.instagram.com/tunaipay.id?igsh=MWd0MWZhM2g5ZnMwNg%3D%3D&utm_source=qr"
          target="_blank"
          rel="noreferrer"
        >
          <img className="w-10" src={instagram} alt="" srcSet="" />
        </a>
        <a href="https://www.tiktok.com/@tunaipay?_t=8nrrKcDi2Nz&_r=1">
          <img className="w-8" src={tiktok} alt="" srcSet="" />
        </a>
        <a
          href="https://www.facebook.com/share/CRx3G5Ydisq7AopK/?mibextid=LQQJ4d"
          target="_blank"
          rel="noreferrer"
        >
          <img className="w-10" src={facebook} alt="" srcSet="" />
        </a>
        <a href="https://wa.me/6281317780021" target="_blank" rel="noreferrer">
          <img className="w-10" src={whatsapp} alt="" srcSet="" />
        </a>
      </div>
      <div>
        <ul className="flex flex-col sm:flex-row text-center sm:gap-x-20 gap-y-5 sm:gap-y-0 text-white list-disc font-arOneSans">
          <li className="list-none">
            <a href="mailto:info@tunaipay.id" target="_blank" rel="noreferrer">
              info@tunaipay.id
            </a>
          </li>
          <li className="pl-0 sm:pl-10 list-none sm:list-disc">
            <a
              href="https://wa.me/6281317780021"
              target="_blank"
              rel="noreferrer"
            >
              +62 813-1778-0021
            </a>
          </li>
          <li className="pl-0 sm:pl-10 list-none sm:list-disc">
            <NavLink to="/term-and-condition">Terms & Conditions</NavLink>
          </li>
          <li className="pl-0 sm:pl-10 list-none sm:list-disc">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
