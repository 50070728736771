import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedPackage: {},
  selectedEvent: {},
  selectedOrder: {},
  totalOrders: 0,
};

export const orderSlice = createSlice({
  name: 'Order Slice',
  initialState,
  reducers: {
    updateSelectedPackage: (state, action) => {
      state.selectedPackage = action.payload;
    },
    updateSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    updateSelectedOrder: (state, action) => {
      state.selectedOrder = action.payload;
    },
    updateTotalOrders: (state, action) => {
      state.totalOrders = action.payload;
    },
  },
});

export const {
  updateSelectedPackage,
  updateSelectedEvent,
  updateSelectedOrder,
  updateTotalOrders,
} = orderSlice.actions;
export default orderSlice.reducer;
